body {
  color: #666;
  /*font: 1.5em/1.5em LatoWeb;*/
  line-height: 1.6
}

#page {
  overflow: hidden;
  position: relative
}

#MoreyouMAyNeed {
  width: 1150px
}

#top {
  background: #181818;
  color: #fff;
  font-size: .714em;
  text-transform: uppercase
}

  #top p, #top .nav {
    margin: 0
  }

  #top .text-note {
    padding: 18px 0
  }

.dropdown-menu {
  background:  #8BC400 !important;
  border: none;
  border-radius: 0;
  margin: 0
}

  .dropdown-menu > li > a {
    line-height: 1.6;
    padding: 5px 20px 8px
  }

    .dropdown-menu > li > a:hover, .dropdown-menu > li > a:focus {
      background-color: transparent
    }

  .dropdown-menu h3 {
    font-size: 1em;
    font-weight: 700;
    text-transform: uppercase;
    color: #fff
  }

.list-thumbs-pro .product {
  margin-bottom: 0;
  overflow: hidden
}

  .list-thumbs-pro .product + .product {
    border-top: 1px dotted #282828;
    margin-top: 20px;
    padding-top: 20px
  }

.list-thumbs-pro h4 {
  padding-right: 0
}

.list-thumbs-pro .product-thumb-info-image {
  float: left;
  margin-bottom: 0
}

.list-thumbs-pro .product-thumb-info-content {
  padding: 3px 20px 0 75px
}

.list-thumbs-pro .product-remove {
  float: right
}

.list-md-pro {
  color: #666
}

  .list-md-pro .product-thumb-info-content h4 {
    margin-bottom: 15px;
    padding-right: 0;
    text-transform: uppercase
  }

.nav-top > li {
  border-right: 1px solid rgba(255,255,255,0.1)
}

  .nav-top > li > a {
    border-radius: 0;
    color: #fff;
    padding: 18px 15px
  }

    .nav-top > li > a .caret {
      color: #999;
      margin-left: 10px
    }

    .nav-top > li > a:hover, .nav-top > li > a:focus {
      background-color: transparent
    }

  .nav-top > li:first-child {
    border-left: 1px solid rgba(255,255,255,0.1)
  }

.nav-top > .langs > a {
  padding-left: 20px
}

.nav-top .dropdown-menu {
  background: #282828
}

.navbar-main .dropdown-menu li ul.dropdown-menu {
  visibility: hidden;
  opacity: 0;
  transition: visibility 0s,opacity .5s linear;
  left: 200px;
  display: none;
  top: -10px
}

.navbar-main .dropdown-menu li.dropdown:hover > ul.dropdown-menu {
  visibility: visible;
  opacity: 1;
  display: block;
  -webkit-animation: Myslide-down .3s ease-out;
  -moz-animation: Myslide-down .3s ease-out
}

@-webkit-keyframes Myslide-down {
  0% {
    opacity: 0;
    -webkit-transform: translateY(-100%)
  }

  100% {
    opacity: 1;
    -webkit-transform: translateY(0)
  }
}

@-moz-keyframes Myslide-down {
  0% {
    opacity: 0;
    -moz-transform: translateY(-100%)
  }

  100% {
    opacity: 1;
    -moz-transform: translateY(0)
  }
}

.nav-top .list-thumbs-pro .product + .product {
  border-top-color: #666
}

.nav .open > a, .nav .open > a:hover, .nav .open > a:focus {
  background-color: transparent;
  border-color: transparent
}

.nav-top .list-thumbs-pro .product + .product {
  border-top-color: #666
}

.nav .open > a, .nav .open > a:hover, .nav .open > a:focus {
  background-color: transparent;
  border-color: transparent
}

.menu-shop {
  padding-right: 15px;
  position: relative
}

  .menu-shop a {
    padding: 14px 15px 14px 12px !important
  }

  .menu-shop i {
    font-size: 1.3em
  }

  .menu-shop .dropdown-menu {
    color: #fff;
    min-width: 415px;
    text-transform: none;
    padding: 20px
  }

    .menu-shop .dropdown-menu a {
      color: #fff
    }

      .menu-shop .dropdown-menu a:hover, .menu-shop .dropdown-menu a:focus {
        color:  #8BC400
      }

    .menu-shop .dropdown-menu .item-cat a {
      color: #999
    }

      .menu-shop .dropdown-menu .item-cat a:hover, .menu-shop .dropdown-menu .item-cat a:focus {
        color:  #8BC400
      }

.cart-buttons {
  padding-top: 20px
}

  .cart-buttons .btn {
    margin-left: 10px
  }

.cart-subtotals {
  border-top: 1px dotted #666;
  font-size: 1.071em;
  margin-top: 20px;
  padding-top: 20px;
  text-transform: uppercase
}

.shopping-bag {
  background-color:  #8BC400;
  border-radius: 50%;
  color: #fff;
  display: block;
  font-size: .714em;
  height: 14px;
  line-height: 14px;
  position: absolute;
  right: 0;
  text-align: center;
  top: 10px;
  width: 14px
}

.langs .dropdown-menu {
  min-width: 75px
}

  .langs .dropdown-menu > li + li {
    border-top: 1px solid #333
  }

  .langs .dropdown-menu > li > a {
    padding-left: 20px;
    padding-right: 15px
  }

.my-account .dropdown-menu {
  font-size: 1em;
  padding: 10px 0;
  left: auto !important;
  right: 0
}

  .my-account .dropdown-menu > li > a {
    color: #fff
  }

    .my-account .dropdown-menu > li > a:hover, .my-account .dropdown-menu > li > a:focus {
      color:  #8BC400
    }

.logo {
  display: block;
  float: left
}

.navbar-main {
  background-color: #fff;
  border-radius: 0;
  border: none;
  margin-bottom: 0;
  box-shadow: 0 6px 18px -6px rgba(0,0,0,0.4);
  z-index: 5
}

  .navbar-main .container {
    position: relative
  }




  .navbar-main .navbar-nav {
    margin: 23px 0 20px;
    margin-left: 0%;
    margin-top: 2%;
  }

    .navbar-main .navbar-nav > li > a {
      color: #0090DA !important;
      padding: 14px 17px;
      text-transform: uppercase;
      font: 1.19em LatoWebBold
    }

      .navbar-main .navbar-nav > li > a:hover, .navbar-main .navbar-nav > li > a:focus {
        color:  #8BC400;
        background-color: transparent
      }

    .navbar-main .navbar-nav > .active > a, .navbar-main .navbar-nav > .active > a:hover, .navbar-main .navbar-nav > .active > a:focus, .navbar-main .navbar-nav > .open > a, .navbar-main .navbar-nav > .open > a:hover, .navbar-main .navbar-nav > .open > a:focus, .navbar-main .navbar-nav .open .dropdown-menu > .active > a, .navbar-main .navbar-nav .open .dropdown-menu > .active > a:hover, .navbar-main .navbar-nav .open .dropdown-menu > .active > a:focus {
      color:  #8BC400;
      background-color: transparent
    }

.navbar-nav > li > .dropdown-menu {
  margin-top: 4px
}

.navbar-main .navbar-nav .open > a:after {
  bottom: -4px;
  height: 0;
  border-bottom: 6px solid  #8BC400;
  border-left: 5px solid rgba(0,0,0,0);
  border-right: 5px solid rgba(0,0,0,0);
  content: "";
  left: 50%;
  margin-left: -5px;
  position: absolute;
  width: 0
}

.navbar-main .navbar-nav .open .dropdown-menu > li > a {
  line-height: 1.6;
  padding: 5px 20px 8px
}

.navbar-main .navbar-nav .dropdown-menu {
  padding: 10px 0;
  min-width: 200px
}

  .navbar-main .navbar-nav .dropdown-menu li a {
    color: #fff
  }

    .navbar-main .navbar-nav .dropdown-menu li a:hover, .navbar-main .navbar-nav .dropdown-menu li a:focus {
      color: #fff;
      background: #21a9e1
    }

  .navbar-main .navbar-nav .dropdown-menu > .active > a, .navbar-main .navbar-nav .dropdown-menu > .active > a:hover, .navbar-main .navbar-nav .dropdown-menu > .active > a:focus {
    background-color: transparent;
    color:  #8BC400
  }

  .navbar-main .navbar-nav .dropdown-menu .item-cat a {
    color: #999
  }

    .navbar-main .navbar-nav .dropdown-menu .item-cat a:hover, .navbar-main .navbar-nav .dropdown-menu .item-cat a:focus {
      color:  #8BC400
    }

.dropdown-submenu {
  position: relative
}

  .dropdown-submenu > .dropdown-menu {
    top: 0;
    left: 100%;
    margin-top: -10px
  }

  .dropdown-submenu > a:after {
    display: block;
    content: " ";
    float: right;
    width: 0;
    height: 0;
    border-color: transparent;
    border-style: solid;
    border-width: 4px 0 4px 4px;
    border-left-color: #ddd;
    margin-top: 6px;
    margin-right: -10px
  }

  .dropdown-submenu:hover > .dropdown-menu {
    display: block
  }

  .dropdown-submenu:hover > a:after {
    border-left-color: #fff
  }

.navbar-nav > .megamenu {
  position: static
}

  .navbar-nav > .megamenu .dropdown-menu {
    left: 0;
    margin: 0 15px;
    padding: 0;
    right: 0
  }

    .navbar-nav > .megamenu .dropdown-menu .sub-menu > li > a {
      display: block;
      padding-bottom: 10px;
      white-space: nowrap
    }

  .navbar-nav > .megamenu .mega-menu-content {
    padding: 0 25px
  }

    .navbar-nav > .megamenu .mega-menu-content .menu-column {
      padding: 30px 20px
    }

      .navbar-nav > .megamenu .mega-menu-content .menu-column + .menu-column {
        border-left: 1px solid #282828;
        display: table-cell;
        float: none
      }

    .navbar-nav > .megamenu .mega-menu-content h3 {
      color: #fff;
      font-family: 'Raleway',sans-serif
    }

    .navbar-nav > .megamenu .mega-menu-content .product-thumb-info-content {
      padding-right: 0
    }

      .navbar-nav > .megamenu .mega-menu-content .product-thumb-info-content p {
        font-family: 'Raleway',sans-serif
      }

.navbar-main .nav-white .megamenu.open > a:after {
  border-bottom-color: rgba(0,0,0,0.1)
}

.nav-white > .megamenu .dropdown-menu {
  background: #fff
}

  .nav-white > .megamenu .dropdown-menu li a {
    color: #666
  }

    .nav-white > .megamenu .dropdown-menu li a:hover, .nav-white > .megamenu .dropdown-menu li a:focus {
      color:  #8BC400
    }

.nav-white > .megamenu .mega-menu-content .menu-column {
  padding: 30px 20px
}

  .nav-white > .megamenu .mega-menu-content .menu-column + .menu-column {
    border-left-color: rgba(0,0,0,0.1)
  }

.nav-white > .megamenu .mega-menu-content h3 {
  color: #333
}

.nav-white > .megamenu .mega-menu-content .sub-menu > li > a {
  color: #666
}

  .nav-white > .megamenu .mega-menu-content .sub-menu > li > a:hover, .nav-white > .megamenu .mega-menu-content .sub-menu > li > a:focus {
    color:  #8BC400
  }

.nav-white > .megamenu .mega-menu-content .list-thumbs-pro .product + .product {
  border-top-color: rgba(0,0,0,0.1)
}

.login-wrapper {
  background:  #8BC400;
  color: #fff;
  padding: 20px 30px;
  position: absolute;
  overflow: hidden;
  right: 0;
  width: 500px;
  z-index: 99;
  -moz-transform: translate3d(100%,0,0);
  -ms-transform: translate3d(100%,0,0);
  -webkit-transform: translate3d(100%,0,0);
  transform: translate3d(100%,0,0);
  -moz-transition: -moz-transform .2s ease-in-out;
  -webkit-transition: -webkit-transform .2s ease-in-out;
  transition: transform .2s ease-in-out
}

  .login-wrapper label {
    color: #fff;
    display: block
  }

  .login-wrapper .form-control {
    background: #fff;
    width: 100%;
    font: .9em LatoWeb
  }

  .login-wrapper .form-group, .login-wrapper .list-inline {
    margin-bottom: 15px
  }

    .login-wrapper .list-inline li + li {
      margin-left: 30px
    }

  .login-wrapper h4 {
    font-size: 1.6em;
    font-weight: 700;
    text-transform: uppercase;
    color: #fff;
    font-family: 'ralewayextralight';
    text-align: center
  }

  .login-wrapper a {
    color: #fff
  }

    .login-wrapper a:hover, .login-wrapper a:focus {
      color: #0a7ac2
    }

.login-btn {
  width: 100%;
  background: #fff;
  border-radius: 0;
  color: #42ABDC
}

  .login-btn:hover {
    background: #42ABDC;
    color: #fff !important
  }

.login-btn2 {
  width: 100%;
  background: #8CC641;
  border-radius: 0;
  color: #fff
}

  .login-btn2:hover {
    background: #42ABDC;
    color: #fff
  }

.register-btn {
  width: 100%;
  background: #42ABDC;
  border-radius: 0;
  color: #fff;
  border: none;
  padding: 12px 0
}

  .register-btn:hover {
    background: #8CC641;
    color: #fff
  }

.centeredText {
  margin: 20px 0;
  text-transform: capitalize;
  font-size: .9em;
  width: 100%;
  text-align: center;
  position: relative;
  background: #8CC641
}

  .centeredText:after {
    content: '';
    width: 100%;
    border-bottom: solid 1px #fff;
    position: absolute;
    left: 0;
    top: 30%;
    z-index: 1
  }

  .centeredText span {
    background: #8CC641;
    width: auto;
    display: inline-block;
    z-index: 3;
    padding: 0 20px;
    color: #fff;
    position: relative;
    font-weight: lighter;
    margin: 0
  }

.social_area {
  display: block
}

.social_box {
  display: block;
  width: 25%;
  float: left;
  padding: 15px 0;
  overflow: hidden;
  font-size: .9em;
  line-height: 1
}

@media only screen and (max-width: 768px) {
  .social_box {
    width: 50%
  }
}

.social_box i {
  margin: 0 !important
}

.social_area a:hover {
  color: #ddd
}

.social_box .icon {
  display: block;
  padding: 0 10px;
  margin-right: 1px;
  float: left;
  color: #FFF;
  font-size: 14px;
  text-align: center;
  top: 0;
  margin-top: 0
}

.fb {
  background: #3B5998
}

.google {
  background: #DD4B39
}

.twitter {
  background: #55ACEE
}

.microsoft {
  background: #FFB901
}


.s02 {
  min-height: 350px;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-pack: center;
  justify-content: center;
  -ms-flex-align: center;
  align-items: center;
  background: url('/assets/images/blue1.jpg');
  background-position: center center;
  padding: 15px;
}

  .s02 form .inner-form {
    background: rgba(0, 0, 0, 0.5);
    padding: 20px 30px;
    display: -ms-flexbox;
    display: flex;
    width: 100%;
    -ms-flex-pack: justify;
    justify-content: space-between;
    -ms-flex-align: center;
    align-items: center;
  }


    .s02 form .inner-form .input-field.second-wrap {
      width: 32.77%;
      min-width: 180px;
    }

    .s02 form .inner-form .input-field.third-wrap {
      width: 180px;
      height: 52px;
      margin-right: 0;
    }



    .s02 form .inner-form .input-field input {
      background: #fff;
      border-radius: 1.5px;
      border: 0;
      display: block;
      width: 100%;
      padding: 10px 32px;
      font-size: 20px;
    }



    .s02 form .inner-form .input-field.third-wrap .btn-search {
      height: 100%;
      width: 100%;
      background:  #8BC400;
      white-space: nowrap;
      font-size: 20px;
      color: #fff;
      transition: all .2s ease-out, color .2s ease-out;
      border: 0;
      cursor: pointer;
    }

.closemodal {
  -webkit-appearance: none;
  padding: 0;
  cursor: pointer;
  background: 0 0;
  border: 0;
  font-size: 45px !important;
}


.postalcodedialogue {
  margin-left: auto !important;
  margin-right: auto !important;
  width: 30% !important;
}


.formwidth {
  width: 100% !important;
}

.second-wrapmargin {
  margin-right: 2px !important;
  width: 100% !important;
}

.rowpadding {
  margin-right: 0px !important;
  margin-left: 0px !important;
}


.modalwidth {
  width: 120% !important;
}


.login-wrapper.open {
  -moz-transform: translate3d(0,0,0);
  -ms-transform: translate3d(0,0,0);
  -webkit-transform: translate3d(0,0,0);
  transform: translate3d(0,0,0)
}

.form-search {
  position: relative
}

  .form-search .form-group {
    width: 100%
  }

  .form-search .form-control {
    background: none;
    border-color: #fff;
    border-right: none;
    border-width: 2px;
    width: 100%
  }

  .form-search .btn {
    border-left: none;
    margin-left: -2px;
    position: absolute;
    right: 0;
    top: 0
  }

.search-wrapper {
  background: #000;
  color: #fff;
  padding-top: 10%
}

  .search-wrapper .close {
    color: #fff;
    font-size: 2.571em
  }

  .search-wrapper .modal-content {
    background-color: transparent;
    border: none;
    border-radius: 0;
    box-shadow: none
  }

.owl-theme .owl-controls {
  left: 0;
  margin-top: 0;
  position: absolute;
  width: 100%;
  z-index: 999
}

  .owl-theme .owl-controls .owl-buttons .owl-prev, .owl-theme .owl-controls .owl-buttons .owl-next {
    background: #333;
    border-radius: 0;
    color: #fff;
    font-size: 1.285em;
    height: 50px;
    line-height: 50px;
    margin: 0;
    filter: alpha(opacity=100);
    opacity: 1;
    padding: 0;
    width: 50px;
    -webkit-transition: all 300ms;
    -moz-transition: all 300ms;
    transition: all 300ms
  }

    .owl-theme .owl-controls .owl-buttons .owl-prev:hover, .owl-theme .owl-controls .owl-buttons .owl-prev:focus, .owl-theme .owl-controls .owl-buttons .owl-next:hover, .owl-theme .owl-controls .owl-buttons .owl-next:focus {
      background-color:  #8BC400
    }

.main-slide .container {
  margin-bottom: 50px;
  margin-top: 50px
}

.item-caption {
  color: #fff;
  text-align: center;
  text-transform: uppercase
}

  .item-caption .item-cat, .item-caption h2 {
    text-shadow: 1px 3px 5px rgba(0,0,0,0.25)
  }

  .item-caption .item-cat {
    margin-bottom: 10px
  }

  .item-caption h2 {
    color: #fff;
    font-size: 4.286em;
    font-line-height: 1;
    text-transform: uppercase;
    letter-spacing: 3px;
    margin-bottom: 40px
  }

.owl-carousel .item {
  position: relative
}

  .owl-carousel .item > img {
    display: inline-block
  }

  .owl-carousel .item .item-caption {
    bottom: 0;
    height: 88%;
    left: 0;
    margin: auto;
    position: absolute;
    right: 0;
    width: 80%
  }

    .owl-carousel .item .item-caption .item-cat a {
      color: #fff
    }

      .owl-carousel .item .item-caption .item-cat a:hover, .owl-carousel .item .item-caption .item-cat a:focus {
        color:  #8BC400
      }

  .owl-carousel .item .item-caption-inner {
    display: table;
    height: 100%;
    width: 100%
  }

  .owl-carousel .item .item-caption-wrap {
    display: block;
    vertical-align: middle
  }

.item-caption-wrap h2 {
  background-color: rgba(0,0,0,.5);
  padding: 13px 20% 13px 20px;
  font: 4.188em/1em ralewayextrabold;
  text-transform: uppercase;
  text-align: left;
  margin-bottom: 0;
  display: block;
  margin-top: 85px
}

.main {
  float: left;
  width: 100%
}

.main-demo .item {
  text-align: center
}

.main-demo .item-caption {
  top: 0
}

.main-demo .owl-controls {
  margin-top: -25px;
  top: 50%
}

  .main-demo .owl-controls .owl-buttons .owl-prev {
    float: left
  }

  .main-demo .owl-controls .owl-buttons .owl-next {
    float: right
  }

.second-demo .item:after {
  background-color: rgba(0,0,0,0.5);
  content: "";
  height: 100%;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
  z-index: 1
}

.second-demo .item .item-caption {
  z-index: 2
}

.product-slide {
  padding-top: 100px
}

  .product-slide .owl-controls {
    top: 0
  }

    .product-slide .owl-controls .owl-buttons .owl-prev, .product-slide .owl-controls .owl-buttons .owl-next {
      background: none;
      border: 2px solid #333;
      color: #333;
      font-size: 2.2em;
      line-height: 45px;
      margin: 0 2px
    }

      .product-slide .owl-controls .owl-buttons .owl-prev:hover, .product-slide .owl-controls .owl-buttons .owl-prev:focus, .product-slide .owl-controls .owl-buttons .owl-next:hover, .product-slide .owl-controls .owl-buttons .owl-next:focus {
        color:  #8BC400;
        background-color: transparent;
        border-color:  #8BC400
      }

.ads {
  background: #f5f5f5;
  border-bottom: 1px solid #ddd;
  border-top: 1px solid #ddd;
  margin-bottom: 50px;
  padding: 50px 0
}

.collections {
  margin-bottom: 50px
}

  .collections .collect-item {
    text-align: center
  }

  .collections h2 {
    font-size: 1.071em;
    margin-bottom: 25px;
    text-transform: uppercase
  }

  .collections p {
    margin-bottom: 25px
  }

.collect-item-thumb {
  display: block;
  margin-bottom: 30px;
  overflow: hidden;
  position: relative
}

  .collect-item-thumb:after {
    border: 1px solid #fff;
    bottom: 5px;
    content: "";
    left: 5px;
    position: absolute;
    right: 5px;
    top: 5px;
    -webkit-transition: opacity 0.35s,-webkit-transform .35s;
    transition: opacity 0.35s,transform .35s
  }

  .collect-item-thumb img {
    -webkit-transition: opacity 0.35s,-webkit-transform .35s;
    transition: opacity 0.35s,transform .35s;
    -webkit-transform: scale(1);
    transform: scale(1)
  }

  .collect-item-thumb:hover img {
    opacity: .7;
    -webkit-transform: scale(1.1);
    transform: scale(1.1)
  }

  .collect-item-thumb:hover:after {
    -webkit-transform: scale(0.55,0.5);
    transform: scale(0.55,0.5);
    filter: alpha(opacity=0);
    opacity: 0
  }

.top-selling {
  margin-bottom: 20px
}

#bg-red {
  background: #d55357
}

.quick-view-pro .container {
  width: 100%
}

.lookbook {
  background: url(/images/bg-wood.jpg) repeat;
  color: #fff;
  font-size: .929em;
  margin-bottom: 60px;
  padding: 30px 30px 10px;
  position: relative;
  text-align: center
}

  .lookbook h2 {
    color: #fff;
    font-size: 1.714em;
    font-weight: 700;
    margin-bottom: 10px;
    position: relative;
    text-transform: uppercase;
    z-index: 2
  }

    .lookbook h2 a {
      color: #fff
    }

      .lookbook h2 a:hover, .lookbook h2 a:focus {
        color:  #8BC400
      }

  .lookbook:after {
    border: 3px solid #fff;
    bottom: 10px;
    content: "";
    left: 10px;
    position: absolute;
    right: 10px;
    top: 10px;
    z-index: 1
  }

.pi-parallax {
  background-position: 50% 0;
  background-size: cover;
  background-repeat: no-repeat;
  background-attachment: fixed;
  background-image: url(/images/content/slides/bg-parallax.jpg);
  color: #fff
}

  .pi-parallax .item {
    display: table;
    height: 400px
  }

    .pi-parallax .item blockquote {
      border-left: 0;
      display: table-cell;
      font-size: 2.571em;
      margin: 0;
      padding: 0;
      text-align: center;
      vertical-align: middle
    }

      .pi-parallax .item blockquote footer {
        color: #fff;
        font-size: .5em;
        padding: 15px 0 0;
        position: relative
      }

        .pi-parallax .item blockquote footer:after {
          border-top: 1px solid #fff;
          content: "";
          left: 50%;
          margin-left: -25px;
          position: absolute;
          top: 0;
          width: 50px
        }

    .pi-parallax .item .intro {
      display: table-cell;
      text-align: center;
      vertical-align: middle
    }

      .pi-parallax .item .intro h2 {
        color: #fff;
        font-size: 4.286em;
        font-line-height: 1;
        text-transform: uppercase;
        letter-spacing: 3px
      }

.parallax-shop {
  background-image: url(/images/content/slides/bg-parallax-2.jpg);
  position: relative
}

  .parallax-shop:after {
    background-color: rgba(0,0,0,0.5);
    content: "";
    height: 100%;
    left: 0;
    position: absolute;
    top: 0;
    width: 100%;
    z-index: 1
  }

  .parallax-shop .owl-carousel {
    text-shadow: 1px 3px 5px rgba(0,0,0,0.25);
    z-index: 2
  }

  .parallax-shop p {
    margin-bottom: 40px
  }

.list-thumbs {
  padding: 50px 0 30px
}

  .list-thumbs h3 {
    font-size: 1em;
    font-weight: 700;
    text-transform: uppercase;
    border-bottom: 2px solid #000;
    padding-bottom: 15px
  }

  .list-thumbs .list-thumbs-pro .product + .product {
    border-top-color: rgba(0,0,0,0.1)
  }

.product-thumbs-slide {
  padding-top: 0
}

  .product-thumbs-slide .owl-controls {
    padding: 0 15px;
    text-align: right;
    top: -63px
  }

    .product-thumbs-slide .owl-controls .owl-buttons .owl-prev, .product-thumbs-slide .owl-controls .owl-buttons .owl-next {
      font-size: 1.714em;
      height: 25px;
      line-height: 25px;
      margin: 0 0 0 10px;
      text-align: center;
      width: 25px
    }

      .product-thumbs-slide .owl-controls .owl-buttons .owl-prev *, .product-thumbs-slide .owl-controls .owl-buttons .owl-next * {
        display: block;
        line-height: .8
      }

.wp-ads {
  list-style: none;
  margin: 50px 0;
  overflow: hidden;
  padding: 0
}

  .wp-ads li {
    background-color:  #8BC400;
    color: #fff;
    display: inline-block;
    float: left;
    overflow: hidden;
    padding: 20px 0 20px 25px;
    width: 20%
  }

    .wp-ads li strong {
      display: block
    }

    .wp-ads li span {
      font-size: .857em;
      filter: alpha(opacity=50);
      opacity: .5
    }

    .wp-ads li .fa {
      float: left;
      font-size: 2.571em;
      margin-right: 10px
    }

    .wp-ads li + li {
      background-color: rgba(26,188,156,0.9)
    }

      .wp-ads li + li + li {
        background-color: rgba(26,188,156,0.8)
      }

        .wp-ads li + li + li + li {
          background-color: rgba(26,188,156,0.7)
        }

          .wp-ads li + li + li + li + li {
            background-color: rgba(26,188,156,0.6)
          }

.latest-blog {
  padding: 80px 0 60px
}

  .latest-blog h3 {
    font-size: 1.071em;
    margin-bottom: 15px
  }

  .latest-blog .post {
    border-bottom: none;
    margin-bottom: 0;
    padding-bottom: 0
  }

.ico-emotion {
  color: #000;
  font-size: 10em;
  filter: alpha(opacity=10);
  opacity: .1;
  line-height: 1
}

.page-404 {
  background: #fafafa;
  padding: 70px 0
}

  .page-404 h2 {
    font-size: 4.286em;
    font-line-height: 1;
    text-transform: uppercase;
    font-weight: 700
  }

.form-search2 {
  margin: 30px 0
}

  .form-search2 .form-control {
    height: 46px
  }

  .form-search2 .form-control, .form-search2 .btn {
    border-color: #ddd
  }

  .form-search2 .btn {
    border-width: 1px;
    min-width: 0;
    padding-left: 20px;
    padding-right: 20px
  }

#googlemaps {
  height: 385px
}

.page-top, .page-top-md, .page-top-lg {
  background-color:  #8BC400;
  color: #fff;
  margin-bottom: 60px;
  text-align: center
}


.page-top {
  background-color: #0090DA;
  margin-bottom: 30px;
  height: 70px
}

  .page-top .container, .page-top-md .container, .page-top-lg .container {
    display: table;
    height: 100px
  }

  .page-top .page-top-in, .page-top-md .page-top-in, .page-top-lg .page-top-in {
    display: table-cell;
    width: 100%;
    vertical-align: middle
  }

  .page-top h2, .page-top-md h2, .page-top-lg h2 {
    color: #fff;
    font-size: 3.429em;
    font-weight: 700;
    margin: 0;
    text-transform: uppercase
  }

    .page-top h2 span, .page-top-md h2 span, .page-top-lg h2 span {
      display: inline-block;
      padding: 11px 31px 11px 35px
    }

.page-top-md {
  background-color: #333;
  background-image: url(/images/bg-blog.jpg);
  background-repeat: no-repeat
}

  .page-top-md .container {
    height: 202px
  }

.page-top-lg {
  background-color: #333;
  background-image: url(/images/bg-contact.jpg);
  background-repeat: no-repeat
}

  .page-top-lg .container {
    height: 384px
  }

  .page-top-lg h2 span {
    border: 2px solid #fff
  }

.contact-content {
  margin-bottom: 60px
}

  .contact-content h3 {
    font-weight: 700;
    text-transform: uppercase
  }

  .contact-content h4 {
    font-size: 1em;
    font-weight: 700;
    text-transform: uppercase
  }

.contact-content-full h4 {
  font-size: 1em;
  font-weight: 700;
  text-transform: uppercase;
  border-bottom: 2px solid #000;
  padding-bottom: 15px
}

.contact-intro {
  padding-bottom: 30px
}

.sidebar {
  margin-bottom: 50px
}

  .sidebar .block {
    position: relative
  }

    .sidebar .block + .block {
      margin-top: 40px;
      padding-top: 40px
    }

      .sidebar .block + .block:after {
        background: #ddd;
        content: "";
        height: 1px;
        left: 0;
        position: absolute;
        top: 0;
        width: 30px
      }

.block h4 {
  font-size: 1em;
  font-weight: 700;
  text-transform: uppercase
}

.block .form-search2 {
  margin: 0
}

.footer {
  background: #12263A;
  color: #999;
  padding: 0;
  border-top: 1px solid #316ca5;
  font-family: 'LatoWeb';
  float: left;
  width: 100%
}

  .footer h2 {
    color: #fff;
    font-size: 1em;
    text-transform: uppercase
  }

  .footer li {
    padding: 0 0 3px;
    width: 48%;
    float: left
  }

  .footer p {
    font: .875em LatoWeb
  }

  .footer i {
    padding-right: 5px;
    font-size: 45px;
    color: #0090DA !important
  }

    .footer i:hover {
      color: #8CC641
    }

  .footer .list-footlinks {
    margin-left: 0
  }

    .footer .list-footlinks li {
      line-height: 1;
      padding: 0 14px 0 0
    }

      .footer .list-footlinks li + li {
        border-left: 1px solid #444;
        padding-left: 16px
      }

  .footer address {
    font: .9em LatoWeb;
    line-height: 2
  }

    .footer address a {
      color: #0090DA !important
    }

      .footer address a:hover {
        color: #8CC641
      }

.upper-foot {
  font-size: .928em;
  padding-bottom: 10px
}

  .upper-foot a {
    font: .85em LatoWeb;
    list-style-type: none;
    color: #707e8c
  }

    .upper-foot a:hover, .upper-foot a:focus {
      color:  #8BC400
    }

  .upper-foot .col-xs-12 {
    text-align: center
  }

.navbar-main-narrow {
  box-shadow: none
}

  .navbar-main-narrow .logo {
    display: inline-block;
    float: none;
    margin: 30px auto
  }

  .navbar-main-narrow .navbar-header {
    border-bottom: 3px solid #282828;
    float: none;
    text-align: center
  }

  .navbar-main-narrow .navbar-collapse {
    border-bottom: 1px solid #ddd;
    padding-left: 0;
    padding-right: 0
  }

  .navbar-main-narrow .navbar-nav > li > a {
    padding: 18px 20px 17px
  }

  .navbar-main-narrow .navbar-nav > .megamenu .dropdown-menu {
    margin-top: -1px
  }

  .navbar-main-narrow .navbar-act {
    font-size: .857em
  }

.navbar-act > li > a {
  color: #333
}

.navbar-default .navbar-toggle {
  border: none;
  border-radius: 0;
  margin: 36px 0 0;
  padding: 0
}

  .navbar-default .navbar-toggle .icon-bar {
    background-color: #333;
    width: 20px
  }

  .navbar-default .navbar-toggle:hover, .navbar-default .navbar-toggle:focus {
    background-color: transparent
  }

    .navbar-default .navbar-toggle:hover .icon-bar, .navbar-default .navbar-toggle:focus .icon-bar {
      background-color:  #8BC400
    }

.navbar-main-drop .menu-shop {
  padding-right: 0
}

.navbar-main-drop .navbar-act {
  font-size: .857em
}

  .navbar-main-drop .navbar-act > li > a {
    padding-bottom: 33px;
    padding-top: 32px
  }

.navbar-main-drop .shopping-bag {
  top: 23px
}

.navbar-main-drop .navbar-header {
  float: none;
  text-align: center
}

  .navbar-main-drop .navbar-header .logo {
    float: none;
    margin-left: auto;
    margin-right: auto
  }

.navbar-main-drop .navbar-toggle {
  display: block;
  float: left
}

.navbar-main-drop .navbar-collapse.collapse {
  display: none !important
}

.navbar-main-drop .navbar-collapse.in {
  overflow-y: auto
}

.navbar-main-drop .collapse.in {
  display: block !important
}

.navbar-main-drop .navbar-left, .navbar-main-drop .pull-right {
  float: none !important
}

.navbar-main-drop .navbar-collapse {
  background-color: #000;
  min-width: 260px;
  padding-left: 0;
  padding-right: 0;
  position: absolute;
  left: 0;
  top: 85px;
  -moz-transform: translate3d(-100%,0,0);
  -ms-transform: translate3d(-100%,0,0);
  -webkit-transform: translate3d(-100%,0,0);
  transform: translate3d(-100%,0,0);
  -moz-transition: -moz-transform .2s ease-in-out;
  -webkit-transition: -webkit-transform .2s ease-in-out;
  transition: transform .2s ease-in-out
}

  .navbar-main-drop .navbar-collapse .navbar-nav > li {
    float: none
  }

    .navbar-main-drop .navbar-collapse .navbar-nav > li > a {
      border-top: 1px solid rgba(255,255,255,0.1);
      color: #fff;
      padding-bottom: 10px;
      padding-top: 10px
    }

      .navbar-main-drop .navbar-collapse .navbar-nav > li > a.dropdown-toggle:before {
        content: "\f107";
        font-family: 'FontAwesome';
        position: absolute;
        right: 15px;
        top: 11px;
        color: #7e7e7e;
        font-size: 1.5em;
        font-weight: 700
      }

      .navbar-main-drop .navbar-collapse .navbar-nav > li > a.dropdown-toggle:hover:before, .navbar-main-drop .navbar-collapse .navbar-nav > li > a.dropdown-toggle:focus:before {
        color: #fff
      }

  .navbar-main-drop .navbar-collapse .dropdown-menu {
    background: none;
    border-top: 1px solid rgba(255,255,255,0.1);
    box-shadow: none;
    float: none;
    padding: 10px 20px !important;
    position: static
  }

  .navbar-main-drop .navbar-collapse .dropdown-submenu > .dropdown-menu {
    border-top: none;
    margin-top: 0
  }

.navbar-main-drop .collapse.in {
  -moz-transform: translate3d(0,0,0);
  -ms-transform: translate3d(0,0,0);
  -webkit-transform: translate3d(0,0,0);
  transform: translate3d(0,0,0)
}

.navbar-main-drop .navbar-nav .open > a:after {
  display: none
}

.navbar-main-drop .navbar-nav .dropdown-menu {
  font-family: 'Raleway',sans-serif;
  font-size: .928em
}

  .navbar-main-drop .navbar-nav .dropdown-menu .dropdown-menu {
    font-size: 1em
  }

.navbar-main-drop .navbar-nav > .megamenu .dropdown-menu {
  margin: 0
}

.navbar-main-drop .navbar-nav > .megamenu .mega-menu-content {
  padding: 0 20px
}

  .navbar-main-drop .navbar-nav > .megamenu .mega-menu-content .row {
    margin-left: 0;
    margin-right: 0
  }

  .navbar-main-drop .navbar-nav > .megamenu .mega-menu-content .menu-column {
    display: block;
    float: none;
    padding: 0;
    width: 100%
  }

    .navbar-main-drop .navbar-nav > .megamenu .mega-menu-content .menu-column + .menu-column {
      border-left: none;
      display: block;
      margin-top: 20px
    }

.navbar-main-drop .dropdown-submenu > a:after {
  display: none
}

.navbar-main-drop .dropdown-submenu > .dropdown-menu {
  display: block
}

.navbar-main-drop .navbar-collapse .navbar-nav > li > a:hover, .navbar-main-drop .navbar-collapse .navbar-nav > li > a:focus {
  background-color:  #8BC400;
  color: #fff
}

.navbar-main-drop .navbar-collapse .navbar-nav > .active > a, .navbar-main-drop .navbar-collapse .navbar-nav > .active > a:hover, .navbar-main-drop .navbar-collapse .navbar-nav > .active > a:focus, .navbar-main-drop .navbar-collapse .navbar-nav > .open > a, .navbar-main-drop .navbar-collapse .navbar-nav > .open > a:hover, .navbar-main-drop .navbar-collapse .navbar-nav > .open > a:focus {
  color: #fff;
  background-color:  #8BC400
}

  .navbar-main-drop .navbar-collapse .navbar-nav > .active > a:before {
    color: #fff
  }

.about {
  margin-bottom: 10px
}

.short-intro {
  margin-bottom: 50px
}

  .short-intro p {
    margin: 0
  }

    .short-intro p strong {
      font-size: .928em;
      text-transform: uppercase
    }

  .short-intro h1 {
    font-size: 4.285em;
    text-transform: uppercase
  }

  .short-intro .divider {
    margin-bottom: 20px
  }

.row-narrow {
  margin-left: -5px;
  margin-right: -5px
}

  .row-narrow div[class^="col-"] {
    padding-left: 5px;
    padding-right: 5px
  }

.cat-description {
  background-color: rgba(0,0,0,0.8);
  bottom: 0;
  color: #fff;
  left: 0;
  padding: 20px;
  position: absolute;
  text-align: center;
  width: 100%;
  -webkit-transition: -webkit-transform .35s;
  transition: transform .35s;
  -webkit-transform: translate3d(0,100%,0);
  transform: translate3d(0,100%,0)
}

  .cat-description h3, .cat-description a {
    -webkit-transition: -webkit-transform .5s;
    transition: transform .5s;
    -webkit-transform: translate3d(0,200%,0);
    transform: translate3d(0,200%,0)
  }

  .cat-description h3 {
    color: #fff;
    font-size: 1.714em;
    margin-bottom: 5px
  }

  .cat-description a {
    color: rgba(255,255,255,0.5);
    font-size: .857em;
    font-weight: 700;
    text-transform: uppercase;
    text-decoration: underline
  }

.cat-item {
  margin-bottom: 10px;
  overflow: hidden;
  position: relative
}

  .cat-item:hover .cat-description, .cat-item:hover h3, .cat-item:hover a {
    -webkit-transform: translate3d(0,0,0);
    transform: translate3d(0,0,0)
  }

.partners {
  margin-bottom: 60px
}

  .partners ul {
    margin-bottom: 0;
    margin-top: 50px
  }

  .partners li {
    margin: 0 25px
  }

  .partners img {
    margin: 0 auto
  }

.highlight {
  padding-bottom: 35px;
  padding-top: 50px
}

.main-slide-boxed .container {
  margin-bottom: 0
}

.pgl-fixed {
  background-color: #f5f5f5
}

  .pgl-fixed #page {
    background-color: #fff;
    max-width: 1170px;
    margin: 0 auto;
    box-shadow: 0 0 10px rgba(0,0,0,0.1)
  }

  .pgl-fixed .container {
    padding-left: 50px;
    padding-right: 50px
  }

  .pgl-fixed .main-slide-boxed .container {
    margin-top: 0;
    padding-left: 0;
    padding-right: 0;
    width: 100%
  }

.franchise-lnk {
  text-transform: uppercase;
  font: .875em LatoBold;
  display: block;
  text-decoration: none;
  padding: 12px 18px;
  color: #fff !important;
  text-align: left;
  background: #8BC400 !important;
  margin-top: 0 !important
}

  .franchise-lnk:hover {
    background: #42ABDC !important
  }

.normal-menu {
  display: block
}

.responsive-menu {
  display: none
}

#Nav_menu_Left label {
  padding: 0 .25em;
  font-size: 40px
}

#Nav_menu_Left .sub-nav .toggle {
  padding: 0 .3125em;
  top: 0;
  -webkit-transform: scale(1,1);
  -ms-transform: scale(1,1);
  transform: scale(1,1);
  font-size: 12px;
  text-align: center
}

#header label:hover, #Nav_menu_Left label:hover {
  color: #ccc
}

#main-nav-check, #Nav_menu_Left .sub-nav-check {
  position: absolute;
  top: -9999px;
  left: -9999px
}

.toggle {
  position: absolute;
  cursor: pointer;
  color: #ddd
}

#container {
  background: #fff;
  min-height: 100%;
  padding: 0;
  margin: 0
}

#Nav_menu_Left, #Nav_menu_Left .sub-nav {
  position: fixed;
  top: 40px;
  bottom: 0;
  width: 17.75em;
  background: #222;
  z-index: 9999;
  left: -17.75em
}

  #container, #Nav_menu_Left, #Nav_menu_Left .sub-nav {
    -webkit-transform: translate(0,0);
    -ms-transform: translate(0,0);
    transform: translate(0,0);
    -webkit-transition: -webkit-transform .25s ease;
    transition: transform .25s ease
  }

    #Nav_menu_Left ul {
      background: #222;
      line-height: 2.75em;
      text-decoration: none;
      padding: 0;
      list-style: none;
      margin-top: 4.0625em;
      border-top: 1px solid #444
    }

    #Nav_menu_Left li {
      border-bottom: 1px solid #444
    }

      #Nav_menu_Left li a {
        font-family: 'LatoWeb';
        background: #222;
        display: block;
        font-size: .875em;
        padding-left: 1.5em;
        color: #fff;
        text-decoration: none
      }

        #Nav_menu_Left li a:hover, #Nav_menu_Left .toggle-sub:hover {
          background: #333
        }

    #Nav_menu_Left .sub-nav {
      margin-top: -1px;
      overflow-y: scroll;
      overflow-x: hidden
    }

    #Nav_menu_Left .sub-heading {
      margin-top: 4.0625em;
      padding-left: 1.3125em;
      color: #999
    }

      #Nav_menu_Left .sub-heading:before {
        content: "";
        display: block;
        height: 0;
        width: 17.75em;
        margin-left: -1.7125em;
        background: #444
      }

    #Nav_menu_Left .toggle-sub {
      font: bold 1em/2.75em Arial;
      color: #fff;
      background: #222;
      float: right;
      margin-top: -2.75em;
      width: 2.25em;
      border-left: 1px solid #444;
      cursor: pointer;
      text-align: center;
      position: absolute;
      right: 0;
      height: 100%
    }

.login a i.fa.fa-user {
  margin: 0
}

#container, #Nav_menu_Left, #Nav_menu_Left .sub-nav, .close-all {
  -webkit-transform: translate3d(0,0,0)
}

  #Nav_menu_Left .close-all {
    display: none
  }

.js #Nav_menu_Left .close-all {
  display: inline
}

.js #Nav_menu_Left .toggle {
  top: 15px;
  z-index: 9999
}

.js #Nav_menu_Left .sub-nav .toggle {
  left: .15em
}

#main-nav-check:checked ~ #container, #main-nav-check:checked ~ #Nav_menu_Left, #Nav_menu_Left .sub-nav-check:checked ~ [id$="-sub"] /* matches all elements with id ending -sub */ {
  -webkit-transform: translate(17.75em,0);
  -ms-transform: translate(17.75em,0);
  transform: translate(17.75em,0)
}

.home-search {
  position: absolute;
  width: 100%;
  z-index: 1;
  left: 0;
  top: 360px
}

.wrapper2 {
  max-width: 1170px;
  margin: 0 auto;
  width: 100%;
  z-index: 99
}

#searchHolder {
  clear: both;
  margin-top: 85px
}

  #searchHolder h2 {
    font: 4.375em TrashHand;
    margin-bottom: 30px;
    text-align: center;
    color: #fc0 !important;
    padding: 0;
    background: none
  }

  #searchHolder .col1, #searchHolder .col2 {
    min-height: 84px
  }

  #searchHolder .col1 {
    background: #0680c8 repeat-x center top;
    text-align: center;
    padding: 20px 10px
  }

    #searchHolder .col1 p {
      text-transform: uppercase;
      font: 1.875em LatoBold;
      color: #fff;
      padding: 0;
      margin: 0
    }

  #searchHolder .col2 {
    background:  #8BC400 repeat-x center top;
    padding: 16px 25px
  }

  #searchHolder .navbar-form {
    background: #fdfdfd repeat-x;
    border: 5px solid #619222;
    width: 100%;
    padding: 0;
    margin: 0;
    border-radius: 6px;
    -moz-border-radius: 6px;
    -webkit-border-radius: 6px
  }

    #searchHolder .navbar-form .formGroup {
      width: 78.53435%;
      float: left
    }

  #searchHolder .formGroup .choose-equip {
    color: #0090DA !important
  }

  #searchHolder input[type="text"], #searchHolder select {
    font: .875em LatoRegular;
    text-transform: uppercase;
    padding: 7px 18px 7px 6px;
    background: none;
    outline: none;
    color: #333;
    width: 100%;
    float: left
  }

.terms {
  margin-top: 50px
}

  .terms h3 {
    margin-bottom: 40px;
    margin-top: 0;
    border: 0;
    border-bottom: 1px solid #e4e4e4;
    font: 2.5em/30px ralewayextrabold;
    text-transform: uppercase;
    padding-bottom: 20px;
    color: #666;
    float: left;
    width: 100%
  }

.aggrement {
  float: left;
  width: 100%;
  padding: 20px;
  background: #f4f4f4;
  margin-bottom: 20px
}

.terms a {
  color: #0090DA !important
}

  .terms a:hover {
    color:  #8BC400
  }

.terms h5 {
  text-transform: uppercase;
  font: 1.2em/1.5em LatoWebBold;
  padding-bottom: 15px;
  color: #666;
  margin: 0;
  float: left;
  width: 100%
}

.contact {
  margin-top: 50px
}

  .contact h3 {
    margin-top: 0;
    border: 0;
    font: 2.5em/30px ralewayextrabold;
    text-transform: uppercase;
    color: #666;
    float: left;
    width: 100%
  }

.breadcrumb {
  padding: 0;
  list-style: none;
  float: left;
  width: 100%;
  margin: 0 0 20px
}

  .breadcrumb > li {
    display: inline-block;
    font-size: 12px
  }

    .breadcrumb > li a {
      text-transform: uppercase;
      color: #666
    }

    .breadcrumb > li + li:before {
      padding: 0 5px;
      color: #000;
      content: ">\00a0"
    }

  .breadcrumb > .active {
    text-transform: uppercase;
    font-size: 12px;
    color: #690
  }

.video-area {
  margin-top: 40px;
  position: relative
}

.about-area {
  position: relative;
  margin-top: 40px;
  padding-top: 30px;
  margin-bottom: 50px
}

.about-area-inner {
  font: 3.125em ralewayextralight;
  padding-top: 35px;
  color: #666
}

#contact-area {
  background: url(/theme/contactBG/Contactbackground.jpg) no-repeat center top;
  background-size: auto;
  padding: 198px 10px 240px;
  background-size: cover;
  color: #fff;
}

.contact-page {
  background: url(/images/contact-page.jpg) no-repeat center top !important;
  padding: 129px 10px 100px !important
}

#contact-area h3 {
  text-align: center;
  color: #fff;
  font-family: 'ralewayextralight';
  font-size: 3.125em;
  font-weight: lighter
}

#contact-area .wrapper {
  background-color: rgba(0,0,0,0.7);
  padding: 20px 15px 15px;
  margin: 0 auto;
  width: 100%;
  max-width: 1110px
}

.btn-center {
  display: flex;
  align-items: center;
  justify-content: center
}

.manual-box {
  margin-right: 1.79533%;
  width: 48.10233%;
  float: left;
  padding: 0
}

  .manual-box h4 {
    text-transform: uppercase;
    font: 1.5em LatoWebBold;
    padding-bottom: 20px;
    margin-bottom: 0
  }

.manualList {
  padding: 0;
  margin: 0 0 30px
}

  .manualList li {
    padding: 0 0 10px;
    list-style-type: none;
    line-height: 30px
  }

    .manualList li span {
      background: #FFB601;
      padding: 8px 5px;
      text-align: center;
      border-bottom: #e08808 2px solid;
      margin-right: 30px
    }

    .manualList li a {
      text-decoration: none;
      padding-top: 3px;
      color: #690
    }

      .manualList li a:hover {
        color: #0090DA !important
      }

.rentals p a {
  color: #0090DA !important;
  text-decoration: none
}

  .rentals p a:hover {
    text-decoration: none;
    color:  #8BC400
  }

.product-title {
  font: 2.5em/30px ralewayextrabold;
  text-transform: uppercase;
  padding-bottom: 10px;
  color: #666;
  text-align: center;
  margin-top: 40px;
  float: left;
  width: 100%;
  margin-bottom: 10px
}

hr {
  padding-bottom: 15px;
  margin: 10px 0 0;
  border: 0;
  border-top: 1px solid #e4e4e4;
  float: left;
  width: 100%
}

.prod-area {
  position: relative;
  margin: 20px 0
}

  .prod-area .info {
    text-transform: uppercase;
    font-size: 16px;
    text-align: center;
    min-height: 70px;
    margin-bottom: 0;
    color: #06c
  }

  .prod-area figure {
    background-color: #fff;
    vertical-align: middle;
    text-align: center;
    margin-bottom: 12px;
    line-height: 515px;
    min-height: 515px;
    padding: 10px;
    -moz-box-shadow: inset 0 0 40px 0 rgba(0,0,0,.1);
    -webkit-box-shadow: inset 0 0 40px 0 rgba(0,0,0,.1);
    box-shadow: inset 0 0 40px 0 rgba(0,0,0,.1)
  }

  .prod-area .weekly-rate {
    font: normal 1em/1.2em LatoWebBold;
    background-color: #0090DA !important;
    text-align: center;
    position: absolute;
    padding: 10px;
    color: #fff;
    width: 100%;
    bottom: 58px;
    left: 0;
    -webkit-transition: background-color .5s;
    -moz-transition: background-color .5s;
    -o-transition: background-color .5s;
    transition: background-color .5s
  }

  .prod-area .btn {
    text-transform: uppercase;
    display: block;
    padding: 12px 16px 10px;
    text-align: center;
    border-radius: 0;
    border: 0;
    font: 1.5em LatoWebBold;
    background:  #8BC400;
    color: #fff !important;
    width: 100%
  }

    .prod-area .btn:hover {
      background: #21a9e1
    }

  .prod-area:hover .weekly-rate {
    background:  #8BC400
  }

  .prod-area .rate {
    font: bold 2em/1.1em LatoWebBold;
    text-transform: uppercase;
    background-color: #0090DA !important;
    display: inline-block;
    line-height: 1.1em;
    text-align: center;
    padding: 43px 12px;
    position: absolute;
    width: 133px;
    right: 5px;
    color: #fff;
    top: 75px;
    border-radius: 50%;
    -moz-border-radius: 50%;
    -webkit-border-radius: 50%;
    transition: background-color .5s;
  }

    .prod-area .rate small {
      line-height: 1em;
      font-size: .429em;
      display: block
    }

  .prod-area:hover .rate {
    background-color: #8DC742
  }

  .prod-area:hover .btn {
    background-color: #0090DA !important
  }

.inner-bg {
  padding: 30px 0 80px
}

.form-box {
  margin-top: 70px
}

.form-top {
  overflow: hidden;
  padding: 0 25px 15px;
  background: #f3f3f3;
  -moz-border-radius: 4px 4px 0 0;
  -webkit-border-radius: 4px 4px 0 0;
  border-radius: 4px 4px 0 0;
  text-align: left
}

.form-top-left {
  float: left;
  width: 75%;
  padding-top: 25px
}

.form-top-right {
  float: left;
  width: 25%;
  padding-top: 5px;
  font-size: 66px;
  color: #ddd;
  line-height: 100px;
  text-align: right
}

.form-bottom {
  padding: 25px 25px 30px;
  background: #eee;
  -moz-border-radius: 0 0 4px 4px;
  -webkit-border-radius: 0 0 4px 4px;
  border-radius: 0 0 4px 4px;
  text-align: left
}

.social-login {
  margin-top: 25px
}

  .social-login h3 {
    font-size: 18px;
    font-weight: 300;
    color: #555;
    line-height: 20px;
    text-align: center
  }

.social-login-buttons {
  margin-top: 25px
}

.middle-border {
  min-height: 300px;
  margin-top: 170px;
  border-right: 1px solid #ddd
}

.btn-link-1.btn-link-1-facebook {
  background: #4862a3
}

.btn-link-1 {
  display: inline-block;
  margin: 5px 2px;
  padding: 7px 15px;
  background: #19b9e7;
  font: .74em LatoWeb;
  font-weight: 300;
  line-height: 16px;
  color: #fff;
  -moz-border-radius: 4px;
  -webkit-border-radius: 4px;
  border-radius: 4px;
  border: none
}

  .btn-link-1.btn-link-1-twitter {
    background: #55acee
  }

  .btn-link-1.btn-link-1-google-plus {
    background: #dd4b39
  }

  .btn-link-1.btn-link-1-microsoft {
    background: #FFB901
  }

.sub-pageWrap {
  background: #fdfdfd;
  padding: 1px 0 0
}

  .sub-pageWrap .container {
    padding-bottom: 20px
  }

  .sub-pageWrap .page-title {
    line-height: 30px !important;
    text-align: center;
    margin: 50px 0 30px;
    font: 1.6em/23px latowebbold;
    text-transform: uppercase;
    padding-bottom: 10px;
    color: #666
  }

.checkout-step1 {
  padding: 36px 24px 20px 48px;
  border: 1px solid #ececec;
  margin-bottom: 35px;
  float: left
}

  .checkout-step1 .checkout-left {
    width: 38.23529%;
    float: left
  }

#show-desc {
  height: 100% !important;
  overflow-y: scroll !important;
  text-align: left
}

.checkout-step1 .checkout-right {
  margin-left: 2.94118%;
  width: 58.82353%;
  float: left
}

.checkout-right h5 {
  font: normal 1.125em/1.2em LatoWebBold;
  text-transform: none;
  color: #06c
}

.checkout-right .checkout-calendar {
  border: 1px solid #ececec;
  padding: 16px 16px 6px
}

.checkout-calendar .product_title {
  display: none
}

.checkout-calendar #wc-bookings-booking-form {
  border: 0;
  padding: 0
}

.wc-bookings-booking-form {
  border: 1px solid #ddd;
  padding: 1em 1em 0;
  margin: 0 0 1em
}

.steps {
  font: normal 45px/.6em ralewayextrabold;
  background-color: #0090DA !important;
  border: 4px solid #81d5f7;
  text-transform: uppercase;
  padding-top: 11px;
  text-align: center;
  display: block;
  height: 80px;
  width: 80px;
  color: #fff;
  border-radius: 50%;
  -moz-border-radius: 50%;
  -webkit-border-radius: 50%;
  margin-top: 5px;
  border-radius: 10px !important;
}

  .steps small {
    line-height: .9em;
    display: block;
    font-size: 18px
  }

.buttonborder {
  text-align: left;
  background: #0090DA !important;
  padding-top: 8px;
  padding-bottom: 5px;
  text-transform: uppercase;
  color: #fff;
  margin: 0;
  text-align: center;
  font-size: 18px
}

.checkout-calendar .checkout-color {
  padding: 0;
  margin: 0
}

  .checkout-calendar .checkout-color li {
    list-style-type: none;
    font-size: .875em;
    padding: 0 0 5px
  }

.red-clr-box {
  float: left;
  width: 30px;
  height: 21px;
  background: #C00;
  margin-right: 5px
}

.blue-clr-box {
  float: left;
  width: 30px;
  height: 21px;
  background: #43E;
  margin-right: 5px
}

.green-clr-box {
  float: left;
  width: 30px;
  height: 21px;
  background: #8CC641;
  margin-right: 5px
}

.myBox {
  border: none;
  padding: 5px;
  height: 550px;
  overflow: scroll;
  overflow-x: hidden;
  float: left;
  width: 96%
}

  .myBox::-webkit-scrollbar {
    width: 14px;
    height: 12px
  }

  .myBox::-webkit-scrollbar-track {
    border: 1px solid #9acd32;
    border-radius: 0px
  }

  .myBox::-webkit-scrollbar-thumb {
    background: #9acd32;
    border-radius: 0px
  }

    .myBox::-webkit-scrollbar-thumb:hover {
      background: #88ba1c
    }

  .myBox a {
    text-transform: uppercase;
    font: 1em/1.5em LatoWebBold;
    padding-bottom: 16px;
    color: #0090DA !important
  }

    .myBox a:hover {
      text-decoration: none;
      color:  #8BC400
    }

.step3 {
  display: block
}

  .step3 .col-sm-3 {
    padding-right: 0
  }

.amount-heading {
  color: #069;
  font-weight: 700;
  text-transform: uppercase;
  text-align: center
}

.amount-box {
  background-color: #ecf7ff;
  border: 1px solid #ececec;
  margin-bottom: 14px;
  padding: 10px 0;
  text-align: center;
  font-size: 22px !important;
  color: #06c;
  font-weight: 700
}

.btn-block {
  padding: 14px 12px 10px;
  text-align: center;
  font-size: 1.875em;
  line-height: 1em
}

.btn.btn-blue.btn-sm {
  font-size: 1.3em
}

.btn-block + .btn-block {
  margin-top: 5px
}

.btn.disabled, .btn[disabled], fieldset[disabled] .btn {
  pointer-events: none;
  cursor: not-allowed;
  filter: alpha(opacity=65);
  -webkit-box-shadow: none;
  box-shadow: none;
  opacity: .65
}

.btn.btn-blue {
  text-transform: uppercase;
  display: inline-block;
  padding: 13px 18px;
  border-radius: 0;
  border: 0;
  font: .875em LatoWebBold;
  background: #0090DA !important;
  color: #fff !important;
}

.btn-block {
  display: block;
  width: 100%;
  padding-right: 0;
  padding-left: 0
}

.padRight {
  padding-right: 14px
}

.steps.right {
  float: right
}

.checkout-calendar p {
  line-height: 1.2em;
  margin-bottom: 10px
}

.input-group-btn {
  font-size: 10px
}

.num-counter .count-value {
  float: left;
  margin: 5px 0 0 5px
}

.btn-number {
  background-color: transparent;
  color: #333;
  font-size: .857em;
  font-weight: 700;
  padding: 10.5px 10px;
  text-transform: uppercase;
  border: #d6d6d6 1px solid
}

.input-values {
  width: 50%;
  float: left
}

.days-rent {
  font-weight: 700;
  float: left;
  width: 100%
}

.paynow-btn {
  background:  #8BC400;
  border: none;
  color: #fff;
  margin: 0 !important
}

  .paynow-btn:hover {
    background: #21a9e1
  }

.addcart-btn {
  background: #0277BD;
  border: none;
  color: #fff
}

  .addcart-btn:hover {
    background:  #8BC400
  }

.modal-content {
  float: left
}

.modal-footer, .modal-body {
  float: left;
  width: 100%
}

.img-height-sm {
  height: 200px !important
}

.modal-header {
  background:  #8BC400;
  color: #fff;
  border-top-left-radius: 6px;
  border-top-right-radius: 6px
}

  .modal-header .modal-title {
    color: #fff
  }

.cart-info {
  clear: both;
  padding: 1em 2em 1em 3.5em !important;
  margin: 0 0 2em !important;
  position: relative;
  background-color: #f7f6f7;
  color: #515151;
  border-top: 3px solid #1e85be;
  list-style: none !important;
  width: auto;
  word-wrap: break-word
}

  .cart-info:before {
    font: normal normal normal 14px/1 FontAwesome;
    content: "\f133";
    display: inline-block;
    position: absolute;
    top: 1em;
    left: 1.5em;
    color: #1e85be;
    font-size: 18px
  }

.cart-cnf {
  border-top: 3px solid #8CC641
}

  .cart-cnf:before {
    color: #8CC641;
    content: "\f041"
  }

.cart-is-empty {
  background:  #8BC400;
  border-radius: 50px;
  text-align: center;
  width: 100px;
  height: 100px;
  display: inline-block;
  color: #fff;
  font-size: 40px;
  line-height: 100px
}

.gotoback-btn {
  background-color: #dad8da;
  text-decoration: none;
  background-image: none;
  color: #515151 !important;
  border: none
}

  .gotoback-btn:hover {
    background-color: #ccc
  }

.checkout-heading {
  padding-bottom: 0;
  font-size: 2.188em;
  font: 2.5em/30px ralewayextrabold;
  color: #666
}

  .checkout-heading span {
    background-color: #21a9e1;
    border: 4px solid #81d5f7;
    vertical-align: middle;
    display: inline-block;
    text-align: center;
    margin-right: 8px;
    padding-top: 15px;
    line-height: .7em;
    height: 84px;
    width: 84px;
    color: #fff;
    border-radius: 50%;
    -moz-border-radius: 50%;
    -webkit-border-radius: 50%
  }

    .checkout-heading span small {
      font-size: .514em;
      display: block;
      color: #fff;
      text-transform: uppercase
    }

.h5-heading {
  text-transform: none;
  font-size: 1.25em
}

.checkout {
  float: left;
  width: 100%;
  margin-top: 50px
}

.remove {
  display: block;
  font-size: 1.5em;
  height: 1em;
  width: 1em;
  text-align: center;
  line-height: 1;
  border-radius: 100%;
  color: red !important;
  text-decoration: none;
  font-weight: 700;
  border: 0
}

  .remove:hover {
    color: #fff !important;
    background: red
  }

.chckout-timer {
  font: normal 12px/1.2em ralewayregular;
  text-transform: uppercase;
  float: right;
  width: 248px;
  margin-bottom: 20px
}

  .chckout-timer .col-md-1 {
    padding-top: 16px;
    width: 92px;
    float: left
  }

.checkout table.shop_table {
  border: 1px solid rgba(0,0,0,.1);
  margin: 0 -1px 24px 0;
  text-align: left;
  width: 100%;
  border-collapse: separate;
  border-radius: 5px
}

  .checkout table.shop_table tbody th, .checkout table.shop_table tfoot td, .checkout table.shop_table tfoot th {
    font-weight: 700;
    border-top: 1px solid rgba(0,0,0,.1)
  }

  .checkout table.shop_table th {
    font-weight: 700;
    padding: 9px 12px
  }

.checkout-form-holder {
  border-top: 5px solid #21a9e1;
  background-color: #f8f7f7;
  padding: 38px 40px 30px;
  margin-bottom: 34px;
  float: left;
  width: 100%
}

  .checkout-form-holder h4, .section_content h4 {
    padding: 0;
    text-transform: none;
    font-size: 1.4em;
    font-weight: 700;
    font-family: 'LatoWebBold';
    color: inherit;
    margin: 0
  }

    .checkout-form-holder h4 span, .section_content h4 span {
      font-size: .8em;
      color: #adadad;
      font-weight: 400
    }

.checkout .required {
  color: red;
  font-weight: 700;
  border: 0;
  font-size: 1.002em
}

.terms .checkbox {
  position: relative;
  display: inline-block;
  min-height: 20px;
  margin: 7px 0 10px 10px
}

#payment {
  background: #ebe9eb;
  border-radius: 5px;
  float: left;
  width: 100%
}

.payment_method {
  padding: 20px;
  border-bottom: 1px solid #d3ced2;
  float: left;
  width: 100%
}

  .payment_method label {
    font-size: 16px
  }

#payment ul.payment_methods {
  text-align: left;
  padding: 1em;
  border-bottom: 1px solid #d3ced2;
  margin: 0;
  list-style: none
}

  #payment ul.payment_methods li {
    line-height: 2;
    text-align: left;
    margin: 0;
    font-weight: 400
  }

#payment div.payment_box {
  position: relative;
  box-sizing: border-box;
  width: 100%;
  padding: 1em;
  margin: 1em 0;
  font-size: 1em;
  border-radius: 2px;
  line-height: 1.5;
  background-color: #dfdcde;
  color: #555;
  float: left
}

  #payment div.payment_box:before {
    content: "";
    display: block;
    border: 1em solid #dfdcde;
    border-right-color: transparent;
    border-left-color: transparent;
    border-top-color: transparent;
    position: absolute;
    top: -.75em;
    left: 0;
    margin: -1em 0 0 2em
  }

.payment_box .form-control {
  border-color: #ddd;
  box-shadow: none;
  height: 47px;
  background-color: #e8ebed
}

#payment div.payment_box input.js-sv-wc-payment-gateway-credit-card-form-account-number {
  background-image: url(/images/card-cc-plain.svg);
  background-repeat: no-repeat;
  background-position: right 10px center;
  background-size: 50px 31px
}

.place0rder {
  background-color: #a46497;
  color: #fff;
  -webkit-font-smoothing: antialiased;
  font-size: 100%;
  margin: 20px;
  line-height: 1;
  cursor: pointer;
  position: relative;
  text-decoration: none;
  overflow: visible;
  padding: .618em 1.3em;
  border-radius: 3px;
  left: auto;
  border: 0;
  white-space: nowrap;
  display: inline-block;
  float: unset;
  font-size: 30px;
  width: 100%;
  margin: 20px 0
}

  .place0rder:hover {
    background-color: #935386;
    color: #fff
  }

.account-box .nav-tabs {
  margin-bottom: 0;
  margin-left: 0;
  position: relative;
  top: 1px;
  float: left;
  width: 100%;
  border: none
}

  .account-box .nav-tabs > li {
    float: left;
    margin-bottom: 4px;
    width: 100%;
    color: #fff;
    background-color: #21a9e1
  }

    .account-box .nav-tabs > li.active > a, .account-box .nav-tabs > li.active > a:hover, .account-box .nav-tabs > li.active > a:focus {
      color: #fff;
      background:  #8BC400;
      border: none;
      font-weight: 600
    }

    .account-box .nav-tabs > li > a {
      text-align: left;
      color: #fff;
      font-size: 17px;
      padding: 14px 10px;
      margin-right: 0;
      float: left;
      width: 100%;
      border-radius: 0
    }

      .account-box .nav-tabs > li > a i {
        display: block;
        float: right;
        margin-top: 3px
      }

    .account-box .nav-tabs > li.active i {
      display: none
    }

.contact-heading-bg {
  float: left;
  width: 100%;
  background: url(/images/contact-detail-bg.jpg) no-repeat;
  height: 90px
}

  .contact-heading-bg .heading {
    float: left;
    width: auto;
    padding: 32px 20px;
    font-size: 29px;
    color: #000
  }

.content-section {
  float: left;
  width: 100%
}

.account-setting-content {
  float: left;
  width: 100%;
  border-left: #00817e 2px solid;
  padding: 5px 10px;
  color: #111;
  text-align: left;
  line-height: 25px;
  font-size: 15px;
  margin: 10px 0
}

.password-heading-bg {
  float: left;
  width: 100%;
  background: url(/images/password-detail-bg.jpg) no-repeat;
  height: 90px
}

.section_content {
  display: block
}

  .section_content .name-title {
    color: #8CC641;
    font-size: 17px;
    font-weight: 600;
    padding: 0
  }

  .section_content .content-info {
    background: #f8f7f7;
    padding: 20px;
    margin: 20px 0 0;
    float: left;
    width: 100%
  }

#cnf-msg {
  display: none
}

.checkout-order-summary {
  background: #f8f7f7;
  padding: 10px
}

  .checkout-order-summary table {
    width: 100%
  }

.contact-detail-form {
  float: left;
  background: #f2f2f2;
  padding: 15px;
  border: #ececec 1px solid;
  margin-top: 0;
  width: 100%
}

  .contact-detail-form .data-area {
    float: left;
    width: 100%;
    padding: 20px 10px;
    border-bottom: #ddd 1px dashed;
    background: #fff;
    margin-bottom: 10px
  }

  .contact-detail-form .data-area-title {
    float: left;
    width: 40%;
    font-size: 17px
  }

  .contact-detail-form .data-area-value {
    float: left;
    width: 58%;
    font-size: 17px;
    font-weight: 600
  }

  .contact-detail-form .data-area-title i {
    color: #11615e;
    background: #ececec;
    padding: 5px 0;
    border-radius: 4px;
    width: 13%;
    text-align: center;
    margin-right: 5px
  }

.datetimepicker-inline {
  width: 600px !important
}

.datetimepicker td, .datetimepicker th {
  font-size: 15px;
  border-radius: 0
}

.datetimepicker table {
  width: 100%
}

  .datetimepicker table thead th {
    border: none !important
  }

.datetimepicker th.switch {
  width: 145px;
  background: #A4A4A4;
  padding: 7px 0 !important;
  color: #fff;
  font-size: 18px;
  text-transform: uppercase;
  font-family: Verdana,Geneva,sans-serif;
  font-weight: 400
}

.datetimepicker th.prev {
  background: #888;
  color: #fff;
  border-top-left-radius: 5px;
  border-bottom-left-radius: 5px
}

.datetimepicker th.next {
  background: #888;
  color: #fff;
  border-top-right-radius: 5px;
  border-bottom-right-radius: 5px
}

.datetimepicker thead tr:first-child th:hover, .datetimepicker tfoot th:hover {
  background: #929292
}

.datetimepicker td, .datetimepicker th {
  padding: 8px 4px !important;
  border: #eee 1px solid
}

.datetimepicker table tr td.new, .datetimepicker table tr td.old {
  background: #AAD275;
  color: #fff
}

  .datetimepicker table tr td.new:hover, .datetimepicker table tr td.old:hover {
    background: #AAD275;
    color: #fff
  }

.datetimepicker table tr td.available {
  background:  #8BC400 !important;
  color: #fff
}

  .datetimepicker table tr td.available:hover {
    background: #AED77A !important;
    color: #fff
  }

.sub-pageWrap {
  background: #fdfdfd;
  padding: 1px 0 0
}

#aboutWrap {
  background: url(/images/aboutBg.jpg) no-repeat top center;
  padding: 124px 10px;
  background-size: cover;
  color: #fff
}

  #aboutWrap .wrapper {
    background-color: rgba(0,0,0,0.5);
    padding: 20px 15px 15px;
    max-width: 1110px;
    margin: 0 auto;
    width: 100%
  }

  #aboutWrap h2 {
    padding-bottom: 46px;
    text-align: center;
    color: #fff;
    font-family: 'ralewayextralight';
    font-size: 3.125em
  }

  #aboutWrap p {
    font: 1.75em/40px ralewayregular;
    font-weight: 400;
    text-align: center;
    padding: 0 0 30px;
    margin: 0
  }

#searchHolder .col1 p {
  text-transform: uppercase;
  font: 1.875em LatoWebBold;
  color: #fff;
  padding: 0;
  margin: 0
}

#searchHolder input[type="text"], #searchHolder select {
  font: .875em LatoWeb
}

#searchHolder h2 {
  font: 4.375em TrashHand;
  margin-bottom: 30px;
  text-align: center;
  color: #fc0 !important;
  padding: 0;
  background: none;
  text-shadow: 1px 1px 2px #000
}

.about h3 {
  margin-top: 40px;
  border: 0;
  margin-bottom: 0;
  font: 2.5em/30px ralewayextrabold;
  text-transform: uppercase;
  padding-bottom: 20px;
  color: #666;
  float: left;
  width: 100%
}


.login a i.fa.fa-user {
  margin-left: -70px !important;
  color: #0090DA;
}




.contact-no {
  font: 1.7em LatoWebBold;
  color: #0090DA !important;
  padding-bottom: 5px;
  padding-top: 8px;
  margin-left: 40px;
}



@media (max-width: 3000px) {

  .navbar-main .navbar-nav > li > a {
    color: #0090DA !important;
    padding: 14px 17px;
    text-transform: uppercase;
    font: 1.4em LatoWebBold;
  }

  .login a i.fa.fa-user {
    margin-left: 0px !important;
  }

  .login {
    margin-top: -3px !important;
  }
}



@media only screen and (max-width: 1350px) and (min-width: 1265px) {
  .logosize {
    width: 95% !important;
  }

  .navbar-main .navbar-nav {
    margin: 23px 30px 20px !important;
    margin-left: 0% !important;
    margin-right: 2px !important;
  }


  legend {
    font: 3.6em LatoWebBold !important;
  }


  .navbar-main .navbar-nav > li > a {
    color: #0090DA !important;
    padding: 14px 12px;
    text-transform: uppercase;
    font: 1em LatoWebBold;
  }


  .login a i.fa.fa-user {
    margin-left: 0px !important;
    color: #0090DA;
  }

  .product-thumb-info-image {
    position: relative;
    height: 230px;
  }
}


@media only screen and (max-width: 1264px) and (min-width: 1200px) {
  .logosize {
    width: 85% !important;
  }

  .navbar-main .navbar-nav {
    margin: 23px 30px 20px !important;
    margin-left: 0% !important;
    margin-right: 40px !important;
  }


  legend {
    font: 3.6em LatoWebBold !important;
  }


  .navbar-main .navbar-nav > li > a {
    color: #0090DA !important;
    padding: 14px 12px;
    text-transform: uppercase;
    font: 1em LatoWebBold;
  }

  .login a i.fa.fa-user {
    margin-left: -30px !important;
    color: #0090DA;
  }

  .product-thumb-info-image {
    position: relative;
    height: 230px;
  }
}

@media only screen and (min-width: 1100px) and (max-width: 1199px) {
  .logosize {
    width: 85% !important;
  }

  .navbar-main .navbar-nav {
    margin: 23px 30px 20px !important;
    margin-left: 0% !important;
    margin-right: 40px !important;
  }


  legend {
    font: 3.6em LatoWebBold !important;
  }

  .modalwidthpostalcode {
    width: 110% !important;
  }

  .navbar-main .navbar-nav > li > a {
    color: #0090DA !important;
    padding: 14px 12px;
    text-transform: uppercase;
    font: .8em LatoWebBold !important;
  }

  .login a i.fa.fa-user {
    margin-left: -30px !important;
    color: #0090DA;
  }



  .dialogboxlogo {
    width: 100%;
    margin-left: 0% !important;
  }

  .postalcodedialogue {
    margin-left: auto !important;
    margin-right: auto !important;
    width: 40% !important;
  }

  .s01 form .inner-form .input-field input {
    background: #fff;
    border-radius: 1.5px;
    border: 0;
    display: block;
    width: 100%;
    padding: 10px 15px !important;
    font-size: 20px;
  }


  .s01 form .inner-form .input-field.second-wrap {
    width: 38.77% !important;
    min-width: 180px;
  }
}


@media only screen and (min-width: 980px) and (max-width: 1099px) {

  .postalcodedialogue {
    margin-left: auto !important;
    margin-right: auto !important;
    width: 45% !important;
  }


  .s01 form .inner-form .input-field input {
    background: #fff;
    border-radius: 1.5px;
    border: 0;
    display: block;
    width: 100%;
    padding: 10px 15px !important;
    font-size: 20px;
  }


  .s01 form .inner-form .input-field.second-wrap {
    width: 42.77% !important;
    min-width: 180px;
  }





  .step4 {
    position: absolute;
    margin-left: -5px !important;
    margin-top: -80px !important;
  }


  .s02 form .inner-form .input-field input {
    background: #fff;
    border-radius: 1.5px;
    border: 0;
    display: block;
    width: 100%;
    padding: 10px 10px;
    font-size: 20px;
  }

  .logosize {
    width: 75% !important;
  }

  .navbar-main .navbar-nav {
    margin: 23px 30px 20px !important;
    margin-left: 0% !important;
    margin-right: 40px !important;
  }


  legend {
    font: 3.6em LatoWebBold !important;
  }

  .modalwidth1 {
    width: 130% !important;
  }



  .modalwidthpostalcode {
    width: 110% !important;
  }

  .navbar-main .navbar-nav > li > a {
    color: #0090DA !important;
    padding: 14px 12px;
    text-transform: uppercase;
    font: .55em LatoWebBold !important;
  }

  .login a i.fa.fa-user {
    margin-left: -30px !important;
    color: #0090DA;
  }



  .dialogboxlogo {
    width: 100%;
    margin-left: 0% !important;
  }
}







@media (max-width: 815px) {
  .paynow-btnnew {
    background:  #8BC400;
    border: none;
    color: #fff;
    margin: 0;
    margin-bottom: 15px !important;
    font-size: 40px !important
  }

  .navbar-main .navbar-nav > li > a {
    color: #0090DA !important
  }


  .stepkitmargin {
    margin-left: 30%;
  }
}



@media screen and (max-width: 850px) {


  .bookonline {
    width: 70% !important;
    margin-top: 20px !important;
    margin-left: 105px !important;
  }

  .ourentalequipmentmargin {
    margin-top: 50px !important;
  }


  .hl {
    border-top: 1px solid #9a9a9a !important;
    width: 100% !important;
  }


  .s01 form .inner-form .input-field.second-wrap {
    width: 100%;
    min-width: auto;
    margin-bottom: -10px;
  }

  .second-wrap {
    margin-right: 0px !important;
  }
}

.step2kit {
  position: absolute;
  margin-top: 100px;
}

.step2kitdays {
  margin-top: 60px
}



@media screen and (max-width: 480px) {



  .stepkitmargin {
    margin-left: 0px;
  }

  .step2kit {
    position: unset;
    margin-top: 100px;
  }

  .step2kitdays {
    margin-top: 60px
  }


  .submitbtnmargin {
    font-size: 26px !important;
  }


  #contact-area {
    padding-bottom: 7% !important;
  }



  .mobileform {
    height: 75px !important;
  }




  .locationmargin {
    margin-bottom: -10px !important;
  }




  .s01 form legend {
    text-transform: uppercase;
    font: 1.9em LatoWebBold !important;
    margin-bottom: 40px;
    padding-top: 15px !important;
  }

  .s01 form .inner-form .input-field.second-wrap {
    width: 100% !important;
    margin-right: 0px !important;
  }

  .s01 form .inner-form .input-field input {
    padding: 5px 15px;
    font-size: 16px;
    padding-top: 10px !important;
  }


  .s01 form .inner-form .input-field.third-wrap {
    width: 100% !important;
  }


  .ourentalequipmentmargin {
    margin-top: 25px !important;
  }



  .online24 {
    text-transform: uppercase;
    font: 2.6em LatoWebBold !important;
    text-align: center;
    margin-top: -60px;
    margin-bottom: 30px;
    color: #0090DA !important;
  }


  .s01 form .inner-form .input-field.third-wrap .btn-search {
    font-size: 36px !important;
    padding-bottom: 55px !important;
  }



  .submitbtn {
    width: 65% !important;
    font-size: 26px !important;
    margin-left: 45px !important;
    text-align: center !important;
    margin-top: 20px;
    padding-top: 13px !important;
  }

  .browsecategory {
    text-align: center !important;
    font-size: 25px;
    margin-bottom: 0px;
    color:  #8BC400;
    font-weight: 900;
    margin-top: 15px !important;
    margin-bottom: 14px !important;
  }

  .top-categories-list {
    margin-bottom: -15px !important;
  }


  .viewcategorymargin {
    margin-top: 15px !important;
  }


  .top-categories-list {
    margin-bottom: -15px !important;
    margin-top: 35px !important;
  }



  .paynow-btn {
    font-size: 40px !important;
    background:  #8BC400;
    border: none;
    color: #fff;
    margin: 0 !important;
  }


  .desktop {
    display: none !important;
  }

  .mobile {
    display: block !important;
  }


  .product-thumb-info-content {
    background: #8CC641;
    padding: 8px 0;
    text-align: center;
    width: 100%;
    text-transform: uppercase;
    position: relative;
    z-index: 9;
    margin: 0px;
    height: 65px;
    font-size: 15px !important;
  }

  .product {
    margin-bottom: 20px !important;
    position: relative;
  }



  .bookonline {
    width: 100% !important;
    margin-top: 20px;
    margin-left: 0px !important;
  }
}


.desktop {
  display: block;
}

.mobile {
  display: none;
}



@media only screen and (max-width: 1600px) and (min-width: 1265px)  
{



      .postalcodedialogue {
    margin-left: auto !important;
    margin-right: auto !important;
    width: 35% !important;
}
     .s01 form .inner-form .input-field input {
    background: #fff;
    border-radius: 1.5px;
    border: 0;
    display: block;
    width: 100%;
    padding: 10px 15px !important;
    font-size: 20px;
}


    .s01 form .inner-form .input-field.second-wrap {
    width: 35.77% !important;
    min-width: 180px;
}





     .logosize {
        width:95% !important;
    }

    .navbar-main .navbar-nav {
    margin: 23px 30px 20px !important;
    margin-left: 0% !important;
    margin-right: 2px !important;
}


    legend {
            font: 3.6em LatoWebBold !important;
    } 


    .navbar-main .navbar-nav > li > a {
    color: #0090DA !important;
    padding: 14px 12px;
    text-transform: uppercase;
    font: 1em LatoWebBold;
}


    .login a i.fa.fa-user {
    margin-left: 0px !important;
    color: #0090DA;
}
   
    .dialogboxlogo {
        width:100%;
        margin-left:0% !important;
    }
     .modalwidthpostalcode {
    width: 110% !important;
}
}


@media only screen and (max-width: 1264px) and (min-width: 1200px)  
{

     .modalwidthpostalcode {
    width: 110% !important;
}

    .logosize {
        width:85% !important;
    }

    .navbar-main .navbar-nav {
    margin: 23px 30px 20px !important;
    margin-left: 0% !important;
        margin-right: 40px !important;
}


    legend {
            font: 3.6em LatoWebBold !important;
    } 


    .navbar-main .navbar-nav > li > a {
    color: #0090DA !important;
    padding: 14px 12px;
    text-transform: uppercase;
    font: 1em LatoWebBold;
}

    .login a i.fa.fa-user {
    margin-left: -30px !important;
    color: #0090DA;
}
    

    .dialogboxlogo {
        width:100%;
        margin-left:0% !important;
    }

       .postalcodedialogue {
    margin-left: auto !important;
    margin-right: auto !important;
    width: 38% !important;
}
     .s01 form .inner-form .input-field input {
    background: #fff;
    border-radius: 1.5px;
    border: 0;
    display: block;
    width: 100%;
    padding: 10px 15px !important;
    font-size: 20px;
}


    .s01 form .inner-form .input-field.second-wrap {
    width: 35.77% !important;
    min-width: 180px;
}

}



@media only screen and (min-width: 1100px) and (max-width: 1199px)   
{
    .logosize {
        width:85% !important;
    }

    .navbar-main .navbar-nav {
    margin: 23px 30px 20px !important;
    margin-left: 0% !important;
        margin-right: 40px !important;
}


    legend {
            font: 3.2em LatoWebBold !important;
    } 

    .modalwidthpostalcode {
    width: 110% !important;
}

    .navbar-main .navbar-nav > li > a {
    color: #0090DA !important;
    padding: 14px 12px;
    text-transform: uppercase;
    font: .8em LatoWebBold !important;
}

    .login a i.fa.fa-user {
    margin-left: -30px !important;
    color: #0090DA;
}
    


    .dialogboxlogo {
        width:100%;
        margin-left:0% !important;
    }

    .postalcodedialogue {
    margin-left: auto !important;
    margin-right: auto !important;
    width: 40% !important;
}
     .s01 form .inner-form .input-field input {
    background: #fff;
    border-radius: 1.5px;
    border: 0;
    display: block;
    width: 100%;
    padding: 10px 15px !important;
    font-size: 20px;
}


    .s01 form .inner-form .input-field.second-wrap {
    width: 38.77% !important;
    min-width: 180px;
}

  .product-thumb-info-content-homepage {
    background: #8CC641;
    padding: 12px 0;
    text-align: center;
    width: 100%;
    text-transform: uppercase;
    position: relative;
    z-index: 9;
    margin: 5px;
    min-height: 60px !important;
    max-height: 70px !important;
    font: 1em 'LatoWebBold';
  }


}


@media only screen and (min-width: 980px) and (max-width: 1099px)   
{

    .postalcodedialogue {
    margin-left: auto !important;
    margin-right: auto !important;
    width: 45% !important;
}


    .s01 form .inner-form .input-field input {
    background: #fff;
    border-radius: 1.5px;
    border: 0;
    display: block;
    width: 100%;
    padding: 10px 15px !important;
    font-size: 20px;
}


    .s01 form .inner-form .input-field.second-wrap {
    width: 42.77% !important;
    min-width: 180px;
}





    .step4 {
        
    position: absolute;
    margin-left: -5px !important;
    margin-top: -80px !important;
}
   

    .s02 form .inner-form .input-field input {
    background: #fff;
    border-radius: 1.5px;
    border: 0;
    display: block;
    width: 100%;
    padding: 10px 10px;
    font-size: 20px;
}

    .logosize {
        width:75% !important;
    }

    .navbar-main .navbar-nav {
    margin: 23px 30px 20px !important;
    margin-left: 0% !important;
        margin-right: 40px !important;
}


    legend {
            font: 3.6em LatoWebBold !important;
    } 

    .modalwidth1 {
    width: 130% !important;
}



    .modalwidthpostalcode {
    width: 110% !important;
}

    .navbar-main .navbar-nav > li > a {
    color: #0090DA !important;
    padding: 14px 12px;
    text-transform: uppercase;
    font: .55em LatoWebBold !important;
}

    .login a i.fa.fa-user {
    margin-left: -30px !important;
    color: #0090DA;
}
    


    .dialogboxlogo {
        width:100%;
        margin-left:0% !important;
    }




}












@media screen and (max-width: 850px) {


    .bookonline {
        width: 70% !important;
        margin-top: 20px !important;
        margin-left: 105px !important;
    }

    .ourentalequipmentmargin {
        margin-top: 50px !important;
    }


    .hl {
    border-top: 1px solid #9a9a9a !important;
    width: 100% !important;


}


    .s01 form .inner-form .input-field.second-wrap {
    width: 100%;
    min-width: auto;
    margin-bottom: -10px;
}
    .second-wrap {
     margin-right: 0px !important;
}

  .online24 {
    text-transform: uppercase;
    font: 2.6em LatoWebBold !important;
    text-align: center;
    margin-top: -60px;
    margin-bottom: 30px;
    width: 100% !important;
    color: #0090DA !important;
  }


    .paynow-btnnew {
    background:  #8BC400;
    border: none;
    color: #fff;
    margin: 0;
    margin-bottom: 15px !important;
    font-size: 40px !important
  }

  .navbar-main .navbar-nav > li > a {
    color: #0090DA !important
  }


  .stepkitmargin {
    margin-left: 30%;
  }


}

.step2kit {
        position: absolute;
        margin-top: 100px;
    }

.step2kitdays {
    margin-top:60px
}

@media (max-width: 815px) {

}



@media screen and (max-width: 480px) {



  .product-thumb-info {
    line-height: 20px !important; 
  }





  .stepkitmargin {
    margin-left: 0px;
  }

    .step2kit {
        position: unset;
        margin-top: 100px;
    }

    .step2kitdays {
    margin-top:60px
}


    .submitbtnmargin {
        
    font-size: 26px !important;
    }


    #contact-area {
        padding-bottom:7% !important;
    }



    .mobileform {
            height: 75px !important;
    }




    .locationmargin {
        margin-bottom:-10px !important;
    }




    .s01 form legend {

            text-transform: uppercase;
    font: 1.9em LatoWebBold !important;
      margin-bottom: 40px; 
          padding-top: 15px !important;



    }

    .s01 form .inner-form .input-field.second-wrap {
        width:100% !important;
        margin-right: 0px !important;

    }

    .s01 form .inner-form .input-field input {
    padding: 5px 15px;
    font-size: 16px;
    padding-top: 10px !important;
}


    .s01 form .inner-form .input-field.third-wrap {
    width: 100% !important;
}


    .ourentalequipmentmargin {
    margin-top: 25px !important;
}



    .online24{
    text-transform: uppercase;
    font: 2.6em LatoWebBold !important;
    text-align: center;
    margin-top: -60px;
    margin-bottom: 30px;
    width:100% !important;
    color: #0090DA !important;
}


    .s01 form .inner-form .input-field.third-wrap .btn-search {
    font-size: 36px !important;
    padding-bottom: 55px !important;
}



     .submitbtn{
    width: 65% !important;
    font-size: 26px !important;
    margin-left: 45px !important; 
    text-align: center !important;
    margin-top: 20px;
        padding-top: 13px !important;
}

     .browsecategory {
    text-align: center !important;
    font-size: 25px;
    margin-bottom: 0px;
    color:  #8BC400;
    font-weight: 900;
    margin-top: 15px !important;
    margin-bottom: 14px !important;
}

.top-categories-list {
    margin-bottom: -15px !important;
}


    .viewcategorymargin {
            margin-top: 15px !important;
    }


    .top-categories-list {
    margin-bottom: -15px !important;
    margin-top: 35px !important;
}



    .paynow-btn {
        font-size: 25px !important;
        background:  #8BC400;
        border: none;
        color: #fff;
        margin: 0 !important;
    }


    .desktop{
    display:none !important;
}

    .mobile {
        display:block !important;
    }


    .product-thumb-info-content {
    background: #8CC641;
    padding: 8px 0;
    text-align: center;
    width: 100%;
    text-transform: uppercase;
    position: relative;
    z-index: 9;
    margin: 0px;
    height: 65px;
    font-size: 15px !important;
}

    .product {
    margin-bottom: 20px !important;
    position: relative;
}



    .bookonline {
        width: 100% !important;
    margin-top: 20px;
    margin-left: 0px !important;
    }




}
