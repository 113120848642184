h1, h2, h3, h4, h5, h6 {
	color: #333;
	margin: 0 0 20px
}





p, ul, ol {
	margin: 0 0 20px;
	font-family: 'LatoWeb'
}

a {
	color: #0090DA !important;
	-webkit-transition: all 300ms;
	-moz-transition: all 300ms;
	transition: all 300ms
}

	a:hover, a:focus {
		color:  #8BC400;
		text-decoration: none
	}

address {
	line-height: 2.4
}

small, .post-meta {
	font-size: 95%
}

input, button, select, textarea {
	background: #fafafa;
	border: 1px solid #ddd
}

textarea {
	resize: none
}

blockquote p {
	line-height: 1.4
}

	blockquote p:before {
		content: "\'\' "
	}

	blockquote p:after {
		content: " \'\'"
	}

blockquote footer:before {
	display: none
}

.drop-caps:first-letter {
	color:  #8BC400;
	float: left;
	font-size: 5.357em;
	line-height: 60px;
	padding: 4px;
	margin-right: 5px;
	margin-top: 5px
}

.drop-caps.secondary:first-letter {
	background-color:  #8BC400;
	border-radius: 3px;
	color: #fff;
	padding: 6px;
	margin-right: 5px
}

.form-control {
	border-color: #ddd;
	box-shadow: none;
	height: 41px
}

	.form-control:focus {
		border-color: #666;
		box-shadow: none
	}

.input-lg, .form-horizontal .form-group-lg .form-control {
	font-size: 1em;
	height: 48px
}

.formDropdown {
	width: 100%;
	padding: 7px 40px 10px 15px;
	margin-bottom: 20px;
	border: 1px solid #ddd;
	height: 41px;
	display: block
}

select {
	-webkit-appearance: none;
	-moz-appearance: none;
	text-indent: .01px;
	background: url(../images/dropdown-button.png) no-repeat right -1px #fff;
	cursor: pointer
}

.error {
	color: #f64243
}

.alert {
	margin-bottom: 30px
}

.formDropdown {
	width: 111%;
	padding: 10px 40px 10px 15px
}

select::-ms-expand {
	display: none
}

label {
	font-weight: 400;
	font-family: 'LatoWeb'
}

.btn {
	font-family: 'LatoWeb'
}

.divider {
	background-color: #fff;
	display: block;
	height: 1px;
	margin: 0 auto 15px;
	width: 50px
}

.bg-color {
	background-color: #555
}

.tall {
	margin: 50px 0
}

.short {
	padding-top: 30px !important
}

.img-circle img {
	border-radius: 50%
}

.btn, .form-control {
	border-radius: 0;
	-webkit-transition: all 300ms;
	-moz-transition: all 300ms;
	transition: all 300ms
}

.btn {
  background-color: transparent;
  border: 2px solid  #8BC400;
  color: #333;
  font-size: .857em;
  font-weight: 700;
  padding: 10px 30px;
  text-transform: uppercase
}

.btn1 {
	color: #666;
	font-size: 14px;
	font-weight: 700 !important;
	margin-top: 30px;
	background-color: transparent;
	border: 2px solid rgba(12,154,220,0.77) !important;
	border-radius: 5px;
	padding: 10px 27px !important;
	text-transform: uppercase
}



	.btn1:hover, .btn:focus {
		background-color:  #8BC400;
		border-color:  #8BC400;
		color: #fff
	}

.btn:hover, .btn:focus {
	background-color:  #8BC400;
	border-color:  #8BC400;
	color: #fff
}

.btn-icon i {
	margin-right: 8px
}

.btn-default {
	background-color: #333;
	color: #fff
}

.btn-primary {
  background-color: #8BC400 !important;
  border-color:  #8BC400;
  color: #fff
}

	.btn-primary:hover, .btn-primary:focus {
		background-color: #0090DA !important;
		border-color: #0090DA !important;
		color: #fff
	}

.btn-white {
	background-color: transparent;
	border-color: #fff;
	color: #fff
}

	.btn-white:hover, .btn-white:focus {
		background-color:  #8BC400;
		border-color:  #8BC400
	}

.btn-grey {
	background-color: #f5f5f5;
	border-color: #f5f5f5;
	color: #666;
	min-width: 50px
}

.btn-black {
	background-color: #000;
	border-color: #000;
	color: #333
}

.btn-greyer {
	border-color: #666;
	color: #666
}

.btn-active {
	background-color:  #8BC400;
	border-color:  #8BC400;
	color: #fff
}

.btn-xs {
	min-width: 0;
	padding: 5px 20px 4px;
	font-size: .8em
}

.btn-sm {
	min-width: 0;
	padding: 9px 20px 8px
}

.btn-lg, .btn-group-lg > .btn {
	padding-left: 35px;
	padding-right: 35px
}

.title {
	margin-bottom: 50px;
	position: relative;
	text-align: center;
	text-transform: capitalize;
	margin-bottom: 25px
}

	.title span {
		padding: 0 40px;
		position: relative;
		z-index: 2;
		font-family: 'ralewayextralight';
		font-size: 1.7em;
		font-weight: lighter
	}

#tit {
	font-family: 'ralewayextrabold';
	color: #0090DA !important;
	font-size: 3.4em;
	padding-bottom: 45px;
	margin: 0
}

.post-info-act {
	left: 50%;
	margin-left: -25px;
	margin-top: -25px;
	position: absolute;
	top: 50%;
	filter: alpha(opacity=0);
	opacity: 0;
	-webkit-transition: opacity 0.35s,-webkit-transform .35s;
	transition: opacity 0.35s,transform .35s;
	-webkit-transform: translate3d(0,350%,0);
	transform: translate3d(0,350%,0);
	z-index: 1
}

	.post-info-act a {
		border: 2px solid #fff;
		border-radius: 50%;
		color: #fff;
		display: inline-block;
		height: 50px;
		line-height: 50px;
		text-align: center;
		width: 50px
	}

.post-image {
	background-color:  #8BC400;
	margin-bottom: 20px;
	overflow: hidden;
	position: relative
}

	.post-image > a > img, .post-image > img {
		filter: alpha(opacity=100);
		opacity: 1;
		-webkit-transition: opacity 0.35s,-webkit-transform .35s;
		transition: opacity 0.35s,transform .35s
	}

	.post-image:hover .post-info-act {
		filter: alpha(opacity=100);
		opacity: 1;
		-webkit-transform: translate3d(0,0,0);
		transform: translate3d(0,0,0)
	}

	.post-image:hover > a > img, .post-image:hover > img {
		filter: alpha(opacity=60);
		opacity: .6;
		-webkit-transform: scale(1.1);
		transform: scale(1.1)
	}

.post-audio, .post-video {
	margin-bottom: 20px
}

.post-meta {
	color: #999
}

	.post-meta a {
		color:  #8BC400
	}

		.post-meta a:hover, .post-meta a:focus {
			color: #333
		}

	.post-meta span {
		margin-right: 10px
	}

		.post-meta span i {
			margin-right: 3px
		}

.pro-tabs {
	border-bottom: none;
	margin-bottom: 50px
}

	.pro-tabs > li {
		display: inline-block;
		float: none;
		margin: 0 1px
	}

		.pro-tabs > li a {
			border-radius: 0;
			background: #fff;
			border: 2px solid #000;
			color: #000;
			font-size: .929em;
			font-weight: 700;
			display: inline-block;
			padding: 12px 0;
			position: relative;
			text-align: center;
			text-transform: uppercase;
			width: 150px
		}

			.pro-tabs > li a:hover {
				background: #000;
				border-color: #000;
				color: #fff
			}

		.pro-tabs > li.active > a, .pro-tabs > li.active > a:hover, .pro-tabs > li.active > a:focus {
			background-color: #000;
			border-color: #000;
			border-width: 2px;
			color: #fff
		}

.second-tabs {
	border-bottom: none;
	font-size: .929em;
	font-weight: 700;
	margin-bottom: 20px;
	margin-left: -3px;
	margin-right: -3px;
	overflow: hidden
}

	.second-tabs li {
		margin: 0 3px 10px
	}

		.second-tabs li a {
			background: #f0f0f0;
			border: none;
			border-radius: 0;
			color: #333;
			display: block;
			margin-right: 0;
			padding: 12px 15px
		}

		.second-tabs li.active:after {
			bottom: -5px;
			height: 0;
			border-top: 5px solid  #8BC400;
			border-left: 5px solid rgba(0,0,0,0);
			border-right: 5px solid rgba(0,0,0,0);
			content: "";
			left: 50%;
			margin-left: -5px;
			position: absolute;
			width: 0
		}

		.second-tabs li.active a {
			background-color:  #8BC400;
			border: none;
			color: #fff
		}

			.second-tabs li.active a:hover, .second-tabs li.active a:focus {
				border: none
			}

.contentMain {
	background: #fff url(../images/contentLogo.png) no-repeat center 53.4%;
	padding: 36px 10px 0;
	text-align: center;
	background-size: 100% !important
}

.banner_headBox1 {
	margin: 0;
	width: 100%;
	text-transform: uppercase;
	display: inline-block;
	padding: 10px 0;
	border-radius: 0;
	border: 0;
	font: 1.125em LatoWebBold;
	background:  #8BC400;
	color: #fff !important;
	text-align: center
}

	.banner_headBox1 a {
		color: #fff
	}

		.banner_headBox1 a:hover {
			color: #0090DA !important;
			text-decoration: none
		}
       
.banner_headBox p {
	background: rgba(12,154,220,0.77);
	padding: 20px 20px 16px !important;
	font: 3.45em LatoWebLight !important;
	text-transform: uppercase;
	color: #fff;
	margin: 0;
	text-align: center;
        margin-top: 0px;
}

.banner_price {
  background: #0090DA !important;
  padding: 20px 20px 0 0 !important;
  font: 3.75em LatoWebLight !important;
  text-transform: uppercase;
  color: #fff;
  margin: 0;
  text-align: center
}

.product {
	margin-bottom: 40px;
	position: relative
}

	.product:hover {
		box-shadow: none
	}

/*.product-thumb-info-content {
  background: #8cc641;
  padding: 12px 0;
  text-align: center;
  width: 100%;
  text-transform: uppercase;
  position: relative;
  z-index: 9;
  line-height: 20px !important;
  font: 1.2em LatoWebBold !important;
  margin: 0;
  min-height: 115px !important;
}*/


.product-thumb-info-content-homepage {
  background: #8BC400 !important;
  padding: 10px 0;
  text-align: center;
  width: 100%;
  text-transform: uppercase;
  position: relative;
  z-index: 9;
  margin: 5px;
  min-height: 70px;
  max-height: 70px;
}


a {
    cursor: pointer;
}





.product-thumb-info-content-text {
	padding: 1px;
}

.product-thumb-info-content a {
	color: #fff;
	font: 1em LatoWebBold;
	font-weight: 600
}

.product-thumb-info-content:hover {
	background: #0090DA !important
}

.steplines {
	margin: 20px 0 0;
	margin-left: 0;
	border: 0;
	border-top-color: currentcolor;
	border-top-style: none;
	border-top-width: 0;
	border-top-color: currentcolor;
	border-top-style: none;
	border-top-width: 0;
	border-top: 1px solid #e4e4e4;
	float: left;
	width: 70%;
	margin-left: 30px
}

.steplines1 {
	margin: 20px 0 0;
	margin-left: 0;
	border: 0;
	border-top-color: currentcolor;
	border-top-style: none;
	border-top-width: 0;
	border-top-color: currentcolor;
	border-top-style: none;
	border-top-width: 0;
	border-top: 1px solid #e4e4e4;
	float: left;
	width: 100%
}

.steplines2 {
	margin: 20px 0 0;
	margin-left: 0;
	border: 0;
	border-top-color: currentcolor;
	border-top-style: none;
	border-top-width: 0;
	border-top-color: currentcolor;
	border-top-style: none;
	border-top-width: 0;
	border-top: 1px solid #e4e4e4;
	float: left;
	width: 100%
}

.wc-bookings-booking-form-withoutborder {
	padding: 1em 1em 0;
	margin: 0 0 1em
}

.bag {
	border-radius: 50%;
	color: #fff;
	font-size: .857em;
	height: 40px;
	line-height: 40px;
	left: 20px;
	position: absolute;
	text-align: center;
	top: 20px;
	width: 40px;
	z-index: 2
}

	.bag a {
		color: #fff
	}

.bag-new {
	background-color:  #8BC400
}

.bag-hot {
	background-color: #f64243
}

.bag-cool, .bag-onsale {
	background-color: #a57bcd
}

.product-thumb-info-act {
	left: 0;
	position: absolute;
	text-align: center;
	bottom: 50%;
	margin-bottom: -25px;
	width: 100%;
	z-index: 3
}

	.product-thumb-info-act a {
		background: #fff;
		color: #333;
		display: inline-block;
		height: 50px;
		line-height: 50px;
		margin: 0 3px;
		opacity: 0;
		width: 50px;
		-webkit-transition: -webkit-transform .3s;
		transition: transform .3s;
		-webkit-transform: translate3d(0,172px,0);
		transform: translate3d(0,172px,0)
	}

		.product-thumb-info-act a:hover, .product-thumb-info-act a:focus {
			background-color:  #8BC400;
			color: #fff
		}

	.product-thumb-info-act .add-to-cart-product {
		-webkit-transition: -webkit-transform .5s;
		transition: transform .5s
	}

.product-thumb-info-image {
  position: relative;
  /*height: 290px*/
  margin-bottom: 15px;
}

	.product-thumb-info-image:hover .product-thumb-info-act a {
		opacity: 1;
		-webkit-transform: translate3d(0,0,0);
		transform: translate3d(0,0,0)
	}

.price {
	color:  #8BC400
}

.item-cat, .price {
	display: block
}

.product-thumb-info-content h4 {
	font-size: 1em;
	line-height: 1.6;
	margin-bottom: 0;
	padding-right: 85px
}

.product-thumb-info-content .item-cat a {
	color: #999
}

	.product-thumb-info-content .item-cat a:hover, .product-thumb-info-content .item-cat a:focus {
		color:  #8BC400
	}

.required {
	color: #e21313
}

.list-icons {
	margin: 0 15px 0 0
}

	.list-icons li {
		padding-left: 0
	}

	.list-icons a {
		border: 1px solid #ddd;
		color: #333;
		display: block;
		height: 40px;
		line-height: 40px;
		text-align: center;
		width: 40px
	}

		.list-icons .active a, .list-icons a:hover, .list-icons a:focus {
			background-color: #333;
			border-color: #333;
			color: #fff
		}

.product-list .product-thumb-info-content .product-thumb-info-act {
	position: static;
	margin-bottom: 0;
	width: auto
}

	.product-list .product-thumb-info-content .product-thumb-info-act .add-to-cart-product {
		background-color: #eee;
		margin: 0 0 0 6px;
		opacity: 1;
		-webkit-transform: translate3d(0,0,0);
		transform: translate3d(0,0,0)
	}

		.product-list .product-thumb-info-content .product-thumb-info-act .add-to-cart-product:hover, .product-list .product-thumb-info-content .product-thumb-info-act .add-to-cart-product:focus {
			background-color:  #8BC400
		}

.form-newsletter {
	position: relative
}

	.form-newsletter .form-group {
		width: 100%
	}

		.form-newsletter .form-group .form-control {
			border: none;
			font-size: .857em;
			padding-right: 45px;
			width: 100%
		}

	.form-newsletter .btn {
		background: #fff;
		border: none;
		height: 35px;
		min-width: 40px;
		padding: 10px 0 9px;
		position: absolute;
		right: 0;
		top: 0;
		text-align: center
	}

.tagclouds a {
	border: 1px solid #484848;
	color: #999;
	display: inline-block;
	margin: 0 2px 5px 0;
	padding: 5px 10px;
	text-transform: capitalize
}

	.tagclouds a:hover, .tagclouds a:focus {
		color:  #8BC400;
		border-color:  #8BC400
	}

.scroll-to-top {
	-webkit-transition: all 300ms;
	-moz-transition: all 300ms;
	transition: all 300ms;
	background-color:  #8BC400;
	bottom: 120px;
	color: #fff;
	display: block;
	font-size: 1.285em;
	height: 50px;
	line-height: 50px;
	opacity: 0;
	position: fixed;
	right: 20px;
	text-align: center;
	text-decoration: none;
	width: 50px;
	z-index: 1040
}

	.scroll-to-top:hover, .scroll-to-top:focus {
		background-color: #333;
		color: #fff
	}

	.scroll-to-top.visible {
		filter: alpha(opacity=10);
		opacity: 1
	}

.breadcrumb {
	background-color: transparent;
	border-radius: 0;
	margin: 20px 0 0;
	padding: 0
}

	.breadcrumb li, .breadcrumb > .active {
		color: #fff
	}

	.breadcrumb > li + li:before {
		color: rgba(255,255,255,0.8)
	}

	.breadcrumb a {
		color: rgba(255,255,255,0.8)
	}

		.breadcrumb a:hover, .breadcrumb a:focus {
			color: #666
		}

.pager {
	margin: 20px 0;
	text-align: right
}

	.pager li > a, .pager li > span {
		background-color: transparent;
		border: none;
		border-radius: 0;
		padding: 0
	}

		.pager li > a:hover, .pager li > a:focus, .pager li > span:hover, .pager li > span:focus {
			background-color: transparent;
			color: #666
		}

	.pager a {
		color: #fff
	}

	.pager li + li {
		border-left: 1px solid #fff;
		margin-left: 6px;
		padding-left: 8px
	}

.pagination {
	margin: 0 0 50px
}

	.pagination li {
		background: #333;
		display: inline-block;
		margin: 0 4px;
		text-align: center
	}

		.pagination li > a, .pagination li > span {
			background-color: #333;
			border: none;
			color: #fff;
			line-height: 40px;
			padding: 0 18px
		}

	.pagination > .disabled > span, .pagination > .disabled > span:hover, .pagination > .disabled > span:focus, .pagination > .disabled > a, .pagination > .disabled > a:hover, .pagination > .disabled > a:focus {
		background-color: #f5f5f5;
		border-color: #f5f5f5;
		color: #333
	}

	.pagination > li:first-child > a, .pagination > li:first-child > span {
		border-bottom-left-radius: 0;
		border-top-left-radius: 0
	}

	.pagination li:last-child > a, .pagination > li:last-child > span {
		border-bottom-right-radius: 0;
		border-top-right-radius: 0
	}

	.pagination > li > a:hover, .pagination > li > span:hover, .pagination > li > a:focus, .pagination > li > span:focus, .pagination > .active > a, .pagination > .active > span, .pagination > .active > a:hover, .pagination > .active > span:hover, .pagination > .active > a:focus, .pagination > .active > span:focus {
		background-color:  #8BC400;
		color: #fff;
		border-color:  #8BC400
	}

.panel-group {
	margin-bottom: 50px
}

	.panel-group .panel-default {
		border-radius: 0;
		border-color: transparent;
		box-shadow: none;
		font-size: .928em
	}

		.panel-group .panel-default + .panel {
			margin-top: 0
		}

		.panel-group .panel-default > .panel-heading {
			background-color: transparent;
			border-color: transparent;
			border-top: 1px solid #333;
			color: #333;
			border-top-left-radius: 0;
			border-top-right-radius: 0;
			padding: 0 0 13px
		}

			.panel-group .panel-default > .panel-heading + .panel-collapse > .panel-body {
				border-top-color: transparent;
				padding: 0
			}

		.panel-group .panel-default h4 {
			padding-top: 15px
		}

		.panel-group .panel-default .panel-title {
			font-size: 1em;
			font-weight: 700;
			position: relative
		}

			.panel-group .panel-default .panel-title:after {
				bottom: -5px;
				height: 0;
				border-top: 5px solid  #8BC400;
				border-left: 5px solid rgba(0,0,0,0);
				border-right: 5px solid rgba(0,0,0,0);
				content: "";
				left: 50%;
				margin-left: -5px;
				position: absolute;
				width: 0;
				border-top-color: #e0e0e0;
				bottom: auto;
				left: auto;
				margin-left: 0;
				right: 0;
				top: 15px
			}

			.panel-group .panel-default .panel-title a {
				display: block
			}

.panel-body.post-comments > .comments > li:first-child {
	border-top: none;
	padding-top: 0
}

.panel-group2 .panel-default {
	font-size: 1em
}

	.panel-group2 .panel-default > .panel-heading {
		border-top: none
	}

	.panel-group2 .panel-default .panel-title {
		font-weight: 400
	}

		.panel-group2 .panel-default .panel-title:after {
			display: none
		}

		.panel-group2 .panel-default .panel-title label {
			display: block
		}

			.panel-group2 .panel-default .panel-title label a {
				display: inline-block
			}

	.panel-group2 .panel-default > .panel-heading + .panel-collapse > .panel-body {
		background-color: #f5f5f5;
		margin-bottom: 15px;
		padding: 15px 20px;
		position: relative
	}

		.panel-group2 .panel-default > .panel-heading + .panel-collapse > .panel-body:after {
			height: 0;
			border-bottom: 6px solid #f5f5f5;
			border-left: 6px solid rgba(0,0,0,0);
			border-right: 6px solid rgba(0,0,0,0);
			content: "";
			left: 15px;
			position: absolute;
			top: -7px;
			width: 0
		}

		.panel-group2 .panel-default > .panel-heading + .panel-collapse > .panel-body p {
			margin: 0
		}

.btn-group a {
	color: #666;
	display: inline-block;
	padding: 9px 15px 8px
}

	.btn-group a:hover, .btn-group a:focus {
		color:  #8BC400
	}

.sitemap {
	padding: 30px 0 60px
}

	.sitemap h3 {
		font-size: 1em;
		font-weight: 700;
		text-transform: uppercase
	}

.mt0 {
	margin-top: 0
}

.mt10 {
	margin-top: 10px
}

.mt15 {
	margin-top: 15px
}

.mt20 {
	margin-top: 20px
}

.mt25 {
	margin-top: 25px
}

.mt30 {
	margin-top: 30px
}

.mb0 {
	margin-bottom: 0
}

.mb10 {
	margin-bottom: 10px
}

.mb15 {
	margin-bottom: 15px
}

.mb20 {
	margin-bottom: 20px
}

.mb25 {
	margin-bottom: 25px
}

.mb30 {
	margin-bottom: 30px
}

.pt0 {
	padding-top: 0
}

.pt10 {
	padding-top: 10px
}

.pt15 {
	padding-top: 15px
}

.pt20 {
	padding-top: 20px
}

.pt25 {
	padding-top: 25px
}

.no-padding {
	padding: 0
}

.pl0 {
	padding-left: 0
}

.pr0 {
	padding-right: 0
}

.manage-box {
	background: #eee;
	padding: 20px;
	margin-bottom: 30px
}

.enabled {
	background: #8CC641;
	padding: 5px 8px;
	color: #fff;
	text-align: center;
	border-radius: 4px
}

.disable {
	background: #B30505;
	padding: 5px 8px;
	color: #fff;
	text-align: center;
	border-radius: 4px;
	margin-left: 10px
}

.form-horizontal .form-group {
	margin-right: 0;
	margin-left: 0
}

.timer {
	font: normal 12px/1.2em ralewayregular;
	text-transform: uppercase;
	float: right;
	width: 248px
}

	.timer .col1 {
		padding-top: 16px;
		width: 92px;
		float: left
	}

	.timer .col2 {
		text-align: center;
		width: 68px;
		float: left
	}

	.timer .col3 {
		text-align: center;
		margin-left: 18px;
		width: 68px;
		float: left
	}

	.timer .timercount {
		font: normal 28px ralewaylight;
		background-color: #21a9e1;
		margin-bottom: 6px;
		text-align: center;
		padding-top: 10px;
		display: block;
		height: 68px;
		width: 68px;
		color: #fff;
		border-radius: 50%;
		-moz-border-radius: 50%;
		-webkit-border-radius: 50%
	}

.radiocustom {
	-webkit-transform: scale(2.5) !important;
	transform: scale(2.5) !important
}

.radio label, .checkbox label {
	padding-left: 35px !important
}

.radiostyle {
	font: 1.9em/30px ralewayextrabold !important;
	padding: 7px;
	background-color: #0090DA !important;
	color: #fff;
	margin: 10px;
	margin-top: 10px !important;
	margin-bottom: 10px !important
}

.radio input[type=radio], .radio-inline input[type=radio], .checkbox input[type=checkbox], .checkbox-inline input[type=checkbox] {
	position: absolute;
	margin-top: 4px \9;
	margin-left: -25px
}

@media(max-width:780px) {
	.radiostyle {
		font: 1.5em/30px ralewayextrabold !important
	}
  .product-thumb-info-content {
    background: #8CC641;
    padding: 12px 0;
    text-align: center;
    width: 100%;
    text-transform: uppercase;
    position: relative;
    z-index: 9;
    line-height: 20px !important;
    font: 1.1em 'LatoWebBold' !important;
    margin: 0px;
    min-height: 65px !important;
  }

}


.banner_price {
	border-radius: 10px;
}