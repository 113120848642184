.mobilecontact {
    display: none !important
}

#logomobile {
    display: none !important
}

.tabsdesktop {
    display: none !important
}

.margindesktop {
  margin-top: 360px;
}























@media only screen and (max-width: 1600px) and (min-width: 1265px) {
  .logosize {
    width: 95% !important;
  }

}













  @media (min-width: 1367px) {
    .list-select .list-sort {
      border-right: 1px solid #333;
      height: 41px
    }

    .list-select .formDropdown {
      width: 109.5%
    }
  }

  @media (max-width:992px) {
    .navbar-main-slide .navbar-act {
      position: absolute;
      right: 30px;
      top: -44px;
    }

    .responsive-menu {
      float: none !important;
      height: 50px !important
    }
  }

  @media (max-width: 1280px) {
    .list-select .list-sort {
      border-right: 1px solid #333;
      height: 41px
    }

    .list-select .formDropdown {
      width: 109.5%
    }
  }



  @media only screen and (min-width: 1100px) and (max-width: 1199px) {
    .logosize {
      width: 85% !important;
    }
  }



  @media only screen and (min-width: 980px) and (max-width: 1099px) {
    .logosize {
      width: 75% !important;
    }
  }








  @media (max-width: 1024px) {
    .navbar-nav > .megamenu .dropdown-menu .sub-menu > li > a {
      white-space: normal
    }

    .wp-ads li {
      padding: 10px 0 10px 10px
    }

      .wp-ads li .fa {
        font-size: 1.857em;
        margin-top: 5px
      }

    .featured-box-cart .formDropdown {
      width: 111.7%
    }
  }

  .featured-box-cart .box-content {
    overflow-x: auto;
    padding-bottom: 30px
  }

  .featured-box-secondary.featured-box-cart .box-content {
    overflow-x: hidden;
    padding-bottom: 0
  }

  .pgl-fixed .container {
    padding-left: 0;
    padding-right: 0
  }

  @media (max-width: 992px) {
    .navbar-header {
      float: left;
      width: 100% !important;
      display: flex;
      justify-content: center
    }

    .logo img {
      width: 100% !important
    }

    .navbar-main .navbar-nav {
      display: flex;
      justify-content: center
    }

      .navbar-main .navbar-nav > li > a {
        padding: 14px 9px;
        color: #fff
      }

        .navbar-main .navbar-nav > li > a:hover, .navbar-main .navbar-nav > li > a:focus {
          color: #fff !important
        }

    .colBody {
      position: fixed;
      margin-left: 60%
    }

    .navbar-default .navbar-toggle {
      display: block
    }

    .navbar-collapse.collapse {
      display: none !important
    }

    .navbar-collapse.in {
      overflow-y: auto
    }

    .collapse.in {
      display: block !important
    }

    .navbar-act {
      position: absolute;
      right: 45px;
      top: 0
    }

    .navbar-main .navbar-nav {
      float: none !important
    }

    .navbar-collapse {
      background-color: #000;
      border-bottom: none !important;
      width: 260px;
      padding-left: 0;
      padding-right: 0;
      position: absolute;
      right: 15px;
      top: 93px
    }

      .navbar-collapse .navbar-nav > li {
        float: none
      }

        .navbar-collapse .navbar-nav > li > a {
          border-top: 1px solid rgba(255,255,255,0.1);
          color: #fff;
          padding-bottom: 10px;
          padding-top: 10px
        }

          .navbar-collapse .navbar-nav > li > a.dropdown-toggle:before {
            content: "\f107";
            font-family: 'FontAwesome';
            position: absolute;
            right: 15px;
            top: 11px;
            color: #7e7e7e;
            font-size: 1.5em;
            font-weight: 700
          }

          .navbar-collapse .navbar-nav > li > a.dropdown-toggle:hover:before, .navbar-collapse .navbar-nav > li > a.dropdown-toggle:focus:before {
            color: #fff
          }

      .navbar-collapse .dropdown-menu {
        background: none;
        border-top: 1px solid rgba(255,255,255,0.1);
        box-shadow: none;
        float: none;
        padding: 0 !important;
        position: static
      }

      .navbar-collapse .dropdown-submenu > .dropdown-menu {
        border-top: none;
        margin-top: 0
      }

    .navbar-main .navbar-nav > li > a {
      padding: 34px 20px 33px;
      margin-top: 0px
    }

      .navbar-main .navbar-nav > li > a i {
        font-size: 1.3em
      }

    .collapse.in {
      -moz-transform: translate3d(0,0,0);
      -ms-transform: translate3d(0,0,0);
      -webkit-transform: translate3d(0,0,0);
      transform: translate3d(0,0,0)
    }

    .navbar-main .navbar-nav .open > a:after {
      display: none
    }

    .navbar-main .navbar-nav .dropdown-menu {
      font-family: 'Raleway',sans-serif;
      font-size: .928em
    }

      .navbar-main .navbar-nav .dropdown-menu .dropdown-menu {
        font-size: 1em
      }

    .navbar-nav > .megamenu .dropdown-menu {
      margin: 0
    }

    .navbar-nav > .megamenu .mega-menu-content {
      padding: 0 20px
    }

      .navbar-nav > .megamenu .mega-menu-content .row {
        margin-left: 0;
        margin-right: 0
      }

      .navbar-nav > .megamenu .mega-menu-content .menu-column {
        display: block;
        float: none;
        padding: 0;
        width: 100%
      }

        .navbar-nav > .megamenu .mega-menu-content .menu-column + .menu-column {
          border-left: none;
          display: block;
          margin-top: 20px
        }

    .dropdown-submenu > a:after {
      display: none
    }

    .dropdown-submenu > .dropdown-menu {
      display: block
    }

    .navbar-main .navbar-collapse .navbar-nav > li > a:hover, .navbar-main .navbar-collapse .navbar-nav > li > a:focus {
      background-color:  #8BC400;
      color: #fff
    }

    .navbar-main .navbar-collapse .navbar-nav > .active > a, .navbar-main .navbar-collapse .navbar-nav > .active > a:hover, .navbar-main .navbar-collapse .navbar-nav > .active > a:focus, .navbar-main .navbar-collapse .navbar-nav > .open > a, .navbar-main .navbar-collapse .navbar-nav > .open > a:hover, .navbar-main .navbar-collapse .navbar-nav > .open > a:focus {
      color: #fff;
      background-color:  #8BC400
    }

      .navbar-main .navbar-collapse .navbar-nav > .active > a:before {
        color: #fff
      }

    .navbar-main-narrow .logo {
      margin: 26px 0
    }

    .navbar-main-narrow .navbar-act > li > a {
      padding-bottom: 34px;
      padding-top: 34px
    }

    .navbar-main-narrow .shopping-bag {
      top: 24px
    }

    .wp-ads li {
      min-height: 80px;
      text-align: center
    }

      .wp-ads li .fa {
        display: block;
        float: none;
        margin-right: 0;
        margin-top: 5px
      }

    .navbar-main-drop .navbar-collapse {
      right: auto
    }

    .navbar-main-drop .navbar-act {
      position: static
    }

    .pi-parallax .item .intro h2 {
      font-size: 2.714em
    }

    .shop_table th, .shop_table td {
      padding-left: 20px;
      padding-right: 20px
    }

    .quickview-wrapper .modal-dialog {
      width: auto
    }

    .quickview-wrapper .product-detail {
      padding: 50px 30px 0
    }

    .normal-menu {
      display: none
    }

    .responsive-menu {
      display: block;
      height: 25px;
      width: 100%;
      background:  #8BC400;
      float: left
    }

    .toggle {
      left: 0;
      color: #fff
    }

      .toggle i {
        padding-left: 90px
      }

    .navbar-main {
      margin-top: 25px
    }

    header {
      float: left;
      width: 100%
    }

    .login {
      position: absolute;
      right: 0;
      margin: -50px -36px 0 0
    }

      .login a {
        padding: 0 !important;
        color: #fff !important
      }

    .franchise-lnk {
      background: #8CC641 !important;
      margin-top: 0 !important
    }

    .login-wrapper {
      width: 450px;
      top: 50px
    }

    .item-caption-wrap h2 {
      font: 2.2em/1em ralewayextrabold
    }

    #searchHolder .col1 p {
      font: 1.3em LatoBold
    }

    .home-search {
      top: 325px
    }

    #searchHolder h2 {
      font: 4em TrashHand;
      margin-bottom: 15px
    }

    .prod-area .rate {
      top: 97px
    }

    .menu-shop {
      top: -40px
    }

      .menu-shop i {
        color: #fff
      }

    .dropdown {
      top: -60px !important;
      color: #fff !important
    }

    .navbar-nav .open .dropdown-menu {
      position: absolute;
      float: right;
      width: auto;
      margin-top: 0;
      background-color: #8cc541;
      border: 0;
      -webkit-box-shadow: none;
      box-shadow: none;
      top: 53px;
      color: #fff
    }

    .navbar-default .navbar-nav .open .dropdown-menu > li > a {
      color: #fff !important
    }

    .checkout-step1 {
      padding: 10px
    }

      .checkout-step1 .checkout-left {
        width: 100%;
        float: left
      }

      .checkout-step1 .checkout-right {
        margin-left: 0;
        width: 100%;
        float: left
      }

    .checkout-img img {
      float: left
    }

    .myBox {
      width: 65%
    }
  }

  @media (max-width: 980px) {
    .featured-box-cart .formDropdown {
      width: 111.8%
    }
  }

  @media (max-width: 850px) {


    .navbar-main-slide .navbar-act {
      position: absolute;
      right: 30px;
      top: -65px;
    }


    .boxwidth {
      width: 50px !important;
      margin-left: -20px !important
    }

    .checkingboxpadding {
      padding-left: 15px ;
    }

    .conditionpadding {
      max-width: 90% !important;
      margin-top: -35px !important;
      margin-left: 25px !important
    }

    .prod-area {
      position: relative;
      margin: 0 0 20px
    }

    .banner_headBox1 {
      margin: 0;
      width: 100%;
      text-transform: uppercase;
      display: inline-block;
      padding: 5px 0 !important
    }

    .wid {
      width: 100% !important
    }

    .owl-carousel .item .item-caption {
      height: 100%;
      width: 72%
    }

    .item-caption h2 {
      font-size: 2em;
      margin-bottom: 20px
    }

    .paynow-btn {
      background:  #8BC400;
      border: none;
      color: #fff;
      margin: 0;
      margin-bottom: 15px !important;
      font-size: 40px !important
    }

    hr {
      padding-bottom: 15px;
      margin: 0 0 -10px;
      border: 0;
      border-top: 1px solid #e4e4e4;
      float: left;
      width: 100%
    }

    .tabsdesktop {
      display: block !important
    }

    .tabsmobile {
      display: none !important
    }

    .margintop {
      margin-top: 0 !important
    }

    .pi-parallax .item {
      height: 300px
    }

      .pi-parallax .item blockquote {
        font-size: 1.857em
      }

    .pgl-fixed .container {
      padding-left: 15px;
      padding-right: 15px
    }

    .login {
      margin: -48px 0 0
    }

    .toggle i {
      padding-left: 33px
    }

    .product-thumb-info-image {
      height: 100%
    }

    .contentMain {
      background: #fff url(../images/contentLogo.png) no-repeat center 97.4%
    }

    #searchHolder .navbar-form {
      height: 46px
    }

    .prod-area .rate {
      top: 65px
    }

    .prod-area .weekly-rate .col-sm-6 {
      float: left;
      width: 49%
    }

    #searchHolder {
      clear: both;
      margin-top: 60px
    }

      #searchHolder .navbar-form {
        border: none !important
      }

      #searchHolder .formGroup .choose-equip {
        border: none !important
      }

      #searchHolder input[type="text"], #searchHolder select {
        font: .875em LatoRegular;
        text-transform: uppercase;
        padding: 10px 18px 7px 165px;
        background: none;
        outline: none;
        color: #333;
        width: 100%;
        float: left
      }

      #searchHolder .formGroup .choose-equip {
        font: 1.8em LatoWebBold !important;
        color: #0090DA !important;
        margin-bottom: -4px !important;
        padding-bottom: 0
      }

      #searchHolder h2 {
        font: 6em TrashHand;
        margin-bottom: 15px;
        margin-top: 35px
      }

    .navbar-main {
      background-color: #fff;
      border-radius: 0;
      border: none;
      margin-bottom: 0;
      box-shadow: none;
      z-index: 5;
      margin-top: 0
    }

    .checkoutmar {
      margin-left: 20px !important
    }

    .toggle {
      left: 0;
      color: #fff;
      margin-top: 10px !important
    }

    .login a i.fa.fa-user {
      margin: 0 !important;
      margin-top: 10px !important
    }

    .contentMain {
      background: #fff url(../images/contentLogo.png) no-repeat center 100% !important;
      padding: 36px 10px 0;
      text-align: center;
      background-size: 100% !important
    }

    .checkout-step1 .checkout-left, .checkout-right {
      width: 100% !important;
      float: none;
      margin: 0 !important
    }

    .toggle {
      position: absolute;
      cursor: pointer;
      color: #fff !important;
      margin-left: -15px !important
    }

    .login {
      margin: -35px 0 0
    }

    .sub-pageWrap .page-title {
      text-align: center;
      margin: 50px 0 30px;
      font: 1.6em/23px ralewayextrabold;
      text-transform: uppercase;
      padding-bottom: 10px;
      color: #666;
      line-height: 28px !important
    }

    .mb15 {
      margin-bottom: 85px
    }

    .footer address {
      font: .9em LatoWeb;
      line-height: 2;
      margin-left: 17px
    }

    .contentMain {
      background: #fff url(../images/contentLogo.png) no-repeat center 80% !important;
      padding: 36px 10px 0;
      margin-top: 320px;
      text-align: center;
      background-size: 100% !important
    }

    .login a i.fa.fa-user {
      margin: 0 !important;
      margin-top: 25px !important
    }

    .js #Nav_menu_Left .toggle {
      left: 25px;
      z-index: 9999
    }

    #Nav_menu_Left label {
      padding: 0 .25em;
      font-size: 65px
    }

    .scroll-to-top {
      display: none !important
    }

    .mobilecontact {
      display: block !important
    }

    .mobilecontact1 {
      display: none !important
    }

    .callfont {
      color: #fff;
      font-size: 35px
    }

    .navbar-nav > li > .dropdown-menu {
      margin-top: -25px !important;
      margin-right: -10px !important
    }

    .dropdown {
      top: -60px !important;
      color: #fff !important
    }

    .navbar-nav .open .dropdown-menu {
      position: absolute;
      float: right;
      width: auto;
      margin-top: 0;
      background-color: #8cc541;
      border: 0;
      -webkit-box-shadow: none;
      box-shadow: none;
      top: 40px !important;
      color: #fff
    }

    .modal-content {
      float: left;
      width: 62% !important
    }

    .js #Nav_menu_Left .sub-nav .toggle {
      left: .15em;
      padding-top: 20px
    }

    #logomobile {
      display: block !important
    }

    #logodesktop {
      display: none !important
    }

    .arrow {
      margin-top: 0 !important;
      width: 17% !important
    }

    menu {
      float: none !important;
      height: 40px !important
    }

    .toggle {
      left: 0;
      color: #fff;
      margin-top: 10px !important;
      font-size: 20px !important
    }

    .login a i.fa.fa-user {
      margin: 0 !important;
      margin-top: 15px !important;
      font-size: 20px
    }

    #searchHolder h2 {
      font: 4.375em TrashHand;
      margin-bottom: 30px;
      text-align: center;
      color: #21a9e1 !important;
      padding: 0;
      background: none;
      text-shadow: 2px 2px 3px #000
    }

    .item-caption h2 {
      margin-bottom: 0
    }

    #MoreyouMAyNeedContainer {
      height: 350px
    }

    #MoreyouMAyNeedFigure {
      min-height: 200px !important;
      max-height: 200px !important;
      margin-top: -20px
    }

    #MoreyouMAyNeed {
      width: 100%
    }

    .twi {
      margin-left: 170px !important
    }

    .fa.fa-linkedin {
      margin-left: 0 !important
    }

    .fa.fa-instagram {
      margin-left: -10px
    }

    .fa.fa-facebook-square {
      margin-left: 178px
    }

    .cookiemar {
      margin-top: 0 !important
    }

    .conmar {
      margin-bottom: -35px
    }
  }

  @media (max-width: 767px) {

    .navbar-main-slide .navbar-act {
      position: absolute;
      right: 30px;
      top: -55px;
    }


    .margindesktop {
      margin-top: 55px;
    }


    .pickupdatemargin {
      margin-left: 40% !important;
    }





    #RelatedProductsImages {
      max-height: 85% !important;
      margin-left: 30% !important;
      width: 40% !important;
      margin-top: 0% !important;
    }

    .mobileproductimg {
      margin-left: 17% !important;
      width: 60% !important;
    }


    .deliverycharges {
      width: 40% !important;
      margin-left: 95px !important;
      position: absolute !important;
    }


    .totalamount {
      width: 40%;
      margin-left: 55%;
    }

    .form-group1 {
      margin-bottom: 15px;
      margin-left: 40px;
      margin-top: 50px;
      width: 39%;
    }


    .step5 {
    }


    .pickupdatealign {
      position: absolute;
      margin-top: -55px !important;
      margin-left: 50% !important;
    }




    .datealign {
      text-align: center;
      margin-top: -16%;
      margin-left: 62%;
      width: 60%;
    }

    .buttonborder {
      width: 60%;
      text-align: center;
    }

    .containerheight {
      min-height: 420px !important;
    }



    .line-margin1 {
      margin-left: 15% !important;
    }


    .line-marginstep3 {
      margin-left: 15% !important;
      margin-bottom: 40px;
    }


    .step2margintop {
      margin-top: 22px !important;
    }

    .steps {
      position: absolute;
      border-radius: 10px !important;
    }


    .stepmargin1 {
      margin-left: 40%;
    }

    .steppaddingleft {
      padding-left: 25%;
    }


    .step4marginleft {
      margin-left: 50%;
    }
  }


  .pickupdatemargin {
    font-size: 40px !important;
    margin-left: 0%;
    width: 100% !important;
    margin-top: 0px !important;
  }


  .pickupmargin {
    margin-bottom: 40px;
    margin-top: -60px;
  }






  .margintop {
    margin-top: 0 !important
  }

  .item-caption h2 {
    margin-bottom: 0
  }

  #MoreyouMAyNeedContainer {
    height: 350px
  }

  #MoreyouMAyNeedFigure {
    min-height: 200px !important;
    max-height: 200px !important;
    margin-top: -20px
  }

  #MoreyouMAyNeed {
    width: 100%
  }



  .fa.fa-linkedin {
    margin-left: 0 !important
  }

  .fa.fa-instagram {
    margin-left: -10px
  }


  .cookiemar {
    margin-top: 0 !important
  }

  .conmar {
    margin-bottom: -35px
  }


  @media (max-width: 736px) {







    .prod-area .rate {
      top: 100px;
      right: 0
    }

    .prod-area figure {
      background-color: #fff;
      vertical-align: middle;
      text-align: center;
      margin-bottom: 12px;
      line-height: 400px !important;
      min-height: 400px !important
    }

    .seotagsfont {
      font-size: 12px
    }

    .prod-area .rate {
      font: bold 1.7em/1.1em LatoWebBold;
      top: 13%;
      height: 85px;
      padding-top: 22px;
      width: 28% !important
    }

    .banner_headBox p {
      background: rgba(12,154,220,0.77);
      padding: 10px 20px !important;
      font: 2.75em LatoWebLight !important;
      text-transform: uppercase;
      color: #fff;
      margin: 0;
      text-align: center
    }

    .ShoppingCartProductList {
      margin: 0;
      padding: 0;
      list-style: none;
      max-height: 170px
    }

    #searchHolder {
      clear: both;
      margin-top: 60px
    }

      #searchHolder .navbar-form {
        border: none !important
      }

      #searchHolder .formGroup .choose-equip {
        border: none !important
      }

      #searchHolder input[type="text"], #searchHolder select {
        font: .875em LatoRegular;
        text-transform: uppercase;
        padding: 10px 18px 7px 165px;
        background: none;
        outline: none;
        color: #333;
        width: 100%;
        float: left
      }

      #searchHolder .formGroup .choose-equip {
        font: 1.4em LatoWebBold !important;
        color: #0090DA !important;
        margin-bottom: -4px !important;
        padding-bottom: 0
      }

      #searchHolder h2 {
        font: 6em TrashHand;
        margin-bottom: 15px;
        margin-top: 35px
      }

    .navbar-main {
      background-color: #fff;
      border-radius: 0;
      border: none;
      margin-bottom: 0;
      box-shadow: none;
      z-index: 5;
      margin-top: 0
    }

    .checkoutmar {
      margin-left: 20px !important
    }

    .toggle {
      left: 0;
      color: #fff;
      margin-top: 10px !important
    }

    .login a i.fa.fa-user {
      margin: 0 !important;
      margin-top: 10px !important
    }

    .contentMain {
      background: #fff url(../images/contentLogo.png) no-repeat center 100% !important;
      padding: 36px 10px 0;
      text-align: center;
      background-size: 100% !important
    }

    .checkout-step1 .checkout-left, .checkout-right {
      width: 100% !important;
      float: none;
      margin: 0 !important
    }

    .toggle {
      position: absolute;
      cursor: pointer;
      color: #fff !important;
      margin-left: -15px !important
    }

    .login {
      margin: -35px 0 0
    }

    .sub-pageWrap .page-title {
      text-align: center;
      margin: 50px 0 30px;
      font: 1.6em/23px ralewayextrabold;
      text-transform: uppercase;
      padding-bottom: 10px;
      color: #666;
      line-height: 28px !important
    }

    .mb15 {
      margin-bottom: 85px
    }

    .footer address {
      font: .9em LatoWeb;
      line-height: 2;
      margin-left: 17px
    }

    .contentMain {
      background: #fff url(../images/contentLogo.png) no-repeat center 50% !important;
      padding: 36px 10px 0;
      margin-top: 320px;
      text-align: center;
      background-size: 100% !important
    }

    .login a i.fa.fa-user {
      margin: 0 !important;
      margin-top: 25px !important
    }

    .js #Nav_menu_Left .toggle {
      left: 25px;
      z-index: 9999
    }

    #Nav_menu_Left label {
      padding: 0 .25em;
      font-size: 65px
    }

    .scroll-to-top {
      display: none !important
    }

    .mobilecontact {
      display: block !important
    }

    .mobilecontact1 {
      display: none !important
    }

    .callfont {
      color: #fff;
      font-size: 35px
    }

    .navbar-nav > li > .dropdown-menu {
      margin-top: -25px !important;
      margin-right: -10px !important
    }

    .dropdown {
      top: -60px !important;
      color: #fff !important
    }

    .navbar-nav .open .dropdown-menu {
      position: absolute;
      float: right;
      width: auto;
      margin-top: 0;
      background-color: #8cc541;
      border: 0;
      -webkit-box-shadow: none;
      box-shadow: none;
      top: 40px !important;
      color: #fff
    }

    .modal-content {
      float: left;
      width: 62% !important
    }

    .js #Nav_menu_Left .sub-nav .toggle {
      left: .15em;
      padding-top: 20px
    }

    #logomobile {
      display: block !important
    }

    #logodesktop {
      display: none !important
    }

    .arrow {
      margin-top: 0 !important;
      width: 17% !important
    }

    menu {
      float: none !important;
      height: 40px !important
    }

    .toggle {
      left: 0;
      color: #fff;
      margin-top: 10px !important;
      font-size: 20px !important
    }

    .login a i.fa.fa-user {
      margin: 0 !important;
      font-size: 20px
    }

    #searchHolder h2 {
      font: 4.375em TrashHand;
      margin-bottom: 30px;
      text-align: center;
      color: #21a9e1 !important;
      padding: 0;
      background: none;
      text-shadow: 2px 2px 3px #000
    }
  }

  @media (max-width: 667px) {


    .navbar-main-slide .navbar-act {
      position: absolute;
      right: 30px;
      top: -55px;
    }

    .fa.fa-facebook-square {
      margin-left: 175px !important;
    }



    .login a i.fa.fa-user {
      margin: 0 !important;
      margin-top: 0px !important;
    }

    .formGroup .choose-equip {
      font: 1.4em LatoWebBold !important;
      color: #0090DA !important;
      margin-bottom: -4px !important;
      padding-bottom: 0;
      margin-left: -40px
    }

    #searchHolder {
      clear: both;
      margin-top: 35px
    }

    .margintop {
      margin-top: 0 !important
    }

    .prod-area .rate {
      font: bold 1.5em/1.1em LatoWebBold;
      top: 18%;
      height: 85px;
      padding-top: 20px;
      width: 45% !important;
    }

    /*#searchholderinputtype:text],#searchHolder select{

    font: .875em LatoRegular;
    text-transform: uppercase;
    padding: 10px 18px 7px 140px;
    background: none;
    outline: none;
    color: #333;
    width: 100%;
    float: left
}*/

    .toggle {
      position: absolute;
      cursor: pointer;
      color: #fff !important;
      margin-left: -15px !important
    }

    .login {
      margin: -35px 0 0
    }

    .sub-pageWrap .page-title {
      text-align: center;
      margin: 50px 0 30px;
      font: 1.6em/23px ralewayextrabold;
      text-transform: uppercase;
      padding-bottom: 10px;
      color: #666;
      line-height: 28px !important
    }

    .mb15 {
      margin-bottom: 105px
    }

    .footer address {
      font: .9em LatoWeb;
      line-height: 2;
      margin-left: 17px
    }

    .contentMain {
      background: #fff url(../images/contentLogo.png) no-repeat center 33% !important;
      padding: 36px 10px 0;
      margin-top: 325px;
      text-align: center;
      background-size: 100% !important
    }

    .scroll-to-top {
      display: none !important
    }

    .dropdown {
      top: -60px !important;
      color: #fff !important
    }

    .navbar-nav > li > .dropdown-menu {
      margin-top: -25px !important;
      margin-right: -10px !important
    }

    .item-caption h2 {
      font-size: 1.7em;
      margin-bottom: 20px
    }

    #logomobile {
      display: block !important
    }

    #logodesktop {
      display: none !important
    }
  }

  @media (max-width: 640px) {
    #MoreyouMAyNeedContainer {
      height: 350px
    }

    #MoreyouMAyNeedFigure {
      min-height: 200px !important;
      max-height: 200px !important;
      margin-top: -20px
    }

    #MoreyouMAyNeed {
      width: 100%
    }

    .navbar-nav {
      margin: 0
    }

    .container > .navbar-header, .container-fluid > .navbar-header, .container > .navbar-collapse, .container-fluid > .navbar-collapse {
      margin-left: 0;
      margin-right: 0
    }

    .nav-top.navbar-right {
      float: right
    }

    .navbar-right .dropdown-menu {
      left: auto;
      right: -1px
    }

    .navbar-act > li {
      float: left
    }

    .pi-parallax .item .intro h2 {
      font-size: 2.142em
    }

    .wp-ads li {
      display: block;
      width: 50%
    }

    .page-top .container, .page-top-md .container, .page-top-lg .container {
      width: 100%
    }

    .manual-box {
      width: 100%
    }

    .prod-area .weekly-rate .col-sm-6 {
      float: left;
      width: 100%;
      margin-top: 10px
    }

    #logomobile {
      display: block !important
    }

    #logodesktop {
      display: none !important
    }
  }

  @media (max-width: 568px) {


    .login a i.fa.fa-user {
      margin: 0 !important;
      margin-top: 20px !important;
    }

    .navbar-main-slide .navbar-act {
      position: absolute;
      right: 30px;
      top: -52px;
    }

    .fa.fa-user {
      margin-top: -10px !important;
    }



    .formGroup .choose-equip {
      font: 1.4em LatoWebBold !important;
      color: #0090DA !important;
      margin-bottom: -4px !important;
      padding-bottom: 0;
      margin-left: 0
    }

    .seotagsfont {
      font-size: 10px !important
    }

    #searchHolder .formGroup .choose-equip {
      font: 1.3em LatoWebBold !important;
      color: #0090DA !important;
      margin-bottom: -4px !important;
      padding-bottom: 0
    }

    .item-caption h2 {
      font-size: 2.142em
    }

    .toggle {
      left: 0;
      color: #fff;
      margin-top: 10px !important
    }

    .login a i.fa.fa-user {
      margin: 0 !important;
      margin-top: 0px !important
    }

    .contentMain {
      background: #fff url(/images/contentLogo.png) no-repeat center 100%
    }

    .fa.fa-facebook-square {
      margin-left: 200px !important
    }

    .fa.fa-linkedin {
      margin-left: 15px !important
    }

    .fa.fa-twitter {
      margin-left: -85px !important
    }

    .fa.fa-instagram {
      margin-left: -110px !important
    }

    .login {
      margin: -33px 0 0
    }

    .checkout-step1 .checkout-left, .checkout-right {
      width: 100% !important;
      float: none;
      margin: 0 !important
    }

    .sub-pageWrap .page-title {
      text-align: center;
      margin: 50px 0 30px;
      font: 1.6em/23px ralewayextrabold;
      text-transform: uppercase;
      padding-bottom: 10px;
      color: #666;
      line-height: 30px !important
    }

    .toggle {
      position: absolute;
      cursor: pointer;
      color: #fff !important;
      margin-left: -20px
    }

    #VpProductLabel {
      font-size: 20px !important
    }

    .footer address {
      font: .9em LatoWeb;
      line-height: 2;
      margin-left: 17px !important
    }

    .mb15 {
      margin-bottom: 100px !important
    }

    #logomobile {
      display: block !important
    }

    #logodesktop {
      display: none !important
    }
  }

  @media (max-width: 480px) {


    .navbar-main-slide .navbar-act {
      position: absolute !important;
      right: 15px !important;
      top: -56px !important;
    }






    .navbar-main .navbar-nav {
      margin: 15px 0 20px !important;
      margin-left: 0%;
    }

    .pickupmargin {
      margin-top: 90px;
    }




    .deliverycharges {
      width: 100% !important;
      margin-left: 0px !important;
      position: unset !important;
    }


    .totalamount {
      width: 100% !important;
      margin-left: 0% !important;
    }


    .a {
      margin-top: 85px;
    }


    .form-group1 {
      margin-bottom: 15px;
      margin-left: 0px !important;
      margin-top: 50px;
      width: 100% !important;
    }

    .paynow-btn {
      font-size: 40px !important;
      background:  #8BC400;
      border: none;
      color: #fff;
      margin: 0 !important;
    }



    .steps {
      position: unset !important;
    }

    .steppaddingleft {
      padding-left: 4%;
    }


    .step5 {
      position: unset !important;
      margin-top: 5px;
      width: 100% !important;
      margin-left: 0px !important;
    }


    .stepmargin1 {
      margin-left: 2% !important;
    }


    .step4 {
      position: unset !important;
      margin-left: -46% !important;
      margin-top: 0px !important;
    }


    .pickupdatemargin {
      font-size: 40px !important;
      margin-left: 0px !important;
      width: 100% !important;
      margin-top: 8px !important;
    }

    .steps {
      font: normal 50px/.8em ralewayextrabold !important;
      background-color: #21a9e1;
      border: 4px solid #81d5f7;
      text-transform: uppercase;
      text-align: center;
      display: block;
      height: 56px;
      width: 56px;
      padding-top: 0 !important;
      color: #fff;
      border-radius: 50%;
      -moz-border-radius: 50%;
      -webkit-border-radius: 50%
    }

    .checkout-heading {
      font-size: 21px !important
    }

    .timer .timercount {
      padding-top: 5px !important
    }

    .steps small {
      line-height: .8em;
      display: block;
      font-size: 40px !important
    }

    .days-rent {
      margin-top: 5px !important;
      font-size: 18px !important
    }

    .checkout-heading span {
      font-size: 40px !important
    }

    .prod-area .info {
      text-transform: uppercase;
      font-size: 11px !important;
      text-align: center;
      padding: 12px 5%;
      min-height: 70px;
      margin-bottom: 0;
      color: #06c
    }

    .ourentalequipmentmargin {
      margin-top: -230px
    }

    .btn-block {
      padding: 14px 12px 10px;
      text-align: center;
      font-size: 1.5em !important;
      line-height: 1em
    }

    .modal-content {
      float: left;
      width: 100% !important
    }

    .prod-area figure {
      line-height: 159px !important;
      min-height: 280px !important;
    }

    #searchHolder {
      clear: both;
      margin-top: -5px
    }

    #VpProductLabel {
      font-size: medium !important
    }

    .days-rent {
      margin-top: 30px
    }

    #RelatedProductsImages {
      max-height: 100% !important;
      margin-left: 25% !important;
      width: 50% !important;
      margin-top: 2% !important;
    }

    .mobileproductimg {
      margin-left: 17% !important;
      width: 60% !important;
    }

    #top .text-note {
      display: none
    }

    .terms h3 {
      font: 2em/30px ralewayextrabold
    }

    .navbar-main-narrow .logo {
      float: left
    }

    .item-caption h2 {
      margin-bottom: 0
    }

    .login-wrapper {
      width: 83%
    }

    .page-top h2, .page-top-md h2, .page-top-lg h2 {
      font-size: 2.857em
    }

    div [class^="col-"] {
      float: none;
      width: 100%
    }

    .form-group [class^="col-"] + [class^="col-"] {
      margin-top: 0
    }

    .pi-parallax .item .intro h2 {
      font-size: 1.428em
    }

    .parallax-shop p {
      margin-bottom: 20px
    }

    .footer [class^="col-xs-"] {
      float: none;
      width: 100%;
      text-align: center
    }

    .below-foot {
      text-align: center
    }

      .below-foot .text-right {
        text-align: center
      }

    .pro-tabs > li a {
      width: 120px
    }

    .shop_table th, .shop_table td {
      padding-left: 0px;
      padding-right: 15px
    }

    .shop_table td {
      padding-bottom: 15px;
      padding-top: 15px
    }
  }

  .toolbar .list-icons {
    margin-bottom: 15px
  }

  .toolbar .list-sort.pull-right {
    float: none !important;
    width: 100%
  }

  .item-caption-wrap h2 {
    font: 1.4em ralewayextrabold;
    padding: 13px 10px;
    margin-top: 45px
  }

  #searchHolder .col1 p {
    font: 1.1em LatoBold
  }

  .home-search {
    top: 250px
  }

  #searchHolder h2 {
    font: 4em TrashHand;
    margin-bottom: 10px;
    margin-top: 15px
  }

  #searchHolder .navbar-form {
    height: 46px
  }

  #searchHolder .col2 {
    margin: 0
  }

  #searchHolder h2 {
    display: block
  }

  #searchHolder .col1 {
    padding: 6px 10px
  }

  #searchHolder .col1, #searchHolder .col2 {
    min-height: 40px;
    margin-top: 0 !important
  }

  .upper-foot {
    float: left
  }

  .logo {
    text-align: center
  }

    .logo img {
      width: 100%;
    }

  .prod-area figure {
    line-height: 200px ;
    min-height: 350px
  }

  .img-dialog {
    line-height: 0 !important;
    margin-top: 35px !important;
    max-width: 80% !important;
    height: 70% !important;
    vertical-align: top !important
  }

  .prod-area figure img {
    width: 100% !important;
    margin-top: 60px
  }

  #MoreyouMAyNeedModalBody {
    /*height: 200px;*/
    overflow: hidden
  }

  .ProductheadingModal {
    font-size: 7px
  }

  /*.prod-area .rate {
    font: bold 1.5em/1.1em LatoWebBold;
    top: 18%;
    height: 85px;
    padding-top: 20px;
    width: 45% !important
}*/

  img-dialog {
    line-height: 0
  }

  .toggle i {
    padding-left: 13px
  }

  /*.navbar-main .navbar-nav > li > a {
    padding: 34px 7px 30px
}*/

  .navbar-act {
    right: 21px
  }

  /*.banner_headBox p {
    font: 2.6em LatoWebLight !important;
    font-weight: 900 !important;
    padding: 5px 20px !important
}*/

  .product-title {
    margin-top: 15px;
    font-size: 30px !important;
    margin-bottom: 5px
  }


  #logomobile {
    display: block !important
  }

  #logodesktop {
    display: none !important
  }

  @media (max-width:480px) {



    .submitbtnmargin {
      margin-bottom: 10px !important;
    }


    .step2space {
      margin-top: -28px !important;
    }


    .form-group {
      margin-bottom: 10px !important;
      margin-top: 0px !important;
    }



    .buttonborder {
      width: 100%;
    }


    .days-rent {
      margin-top: 5% !important;
    }


    .line-margin1 {
      width: 83% !important;
      margin-top: 30px;
      margin-bottom: -20px;
      margin-left: 8% !important;
    }


    .line-marginstep3 {
      margin-left: 8% !important;
    }





    .datealign {
      text-align: center;
      margin-top: 0px !important;
      margin-left: 0px !important;
      width: 100% !important;
    }


    .line-margin {
      width: 83% !important;
      margin-top: 30px;
      margin-bottom: -20px;
    }

    .line-marginstep3 {
      width: 83% !important;
      margin-top: 30px;
      margin-bottom: -10px;
    }



    .line-marginstep5 {
      width: 83% !important;
      margin-top: 30px;
      margin-bottom: -10px;
    }




    .line-margin5 {
      width: 83% !important;
      margin-top: 30px;
      margin-bottom: -20px;
      margin-left: 20px;
    }


    .steps small {
      line-height: 1.9em !important;
    }




    .banner_price {
      margin-top: -110px;
    }


    .boxwidth {
      width: 50px !important;
      margin-left: -20px !important
    }

    .checkingboxpadding {
      padding-left: 15px;
    }

    .conditionpadding {
      max-width: 90% !important;
      margin-top: -35px !important;
      margin-left: 35px !important
    }

    .terms .checkbox {
      font-size: 14px !important;
      position: relative !important;
      display: inline-block !important;
      min-height: 20px !important;
      margin: 40px 0 10px 10px !important
    }

    .productwidth {
      float: left;
      width: 50% !important
    }

    .product-thumb-info-content {
      background: #8CC641;
      padding: 8px 0;
      text-align: center;
      width: 100%;
      text-transform: uppercase;
      position: relative;
      z-index: 9;
      margin: 5px;
      height: 65px;
      font-size: 12px !important;
    }


    .product-thumb-info-content-homepage {
      background: #8CC641;
      padding: 10px 0;
      text-align: center;
      width: 100%;
      text-transform: uppercase;
      position: relative;
      z-index: 9;
      margin: 5px;
      max-height: 70px;
      font-size: 17px
    }

    .navbar-act {
      padding-top: 14px !important
    }

    .responsive-menu {
      padding-top: 10px !important
    }

    #header {
      display: none
    }

    .pt25extra {
      margin-top: 100px !important
    }

    .checkout-step1 .checkout-left, .checkout-right {
      width: 100% !important;
      float: none;
      margin: 0 !important
    }

    .toggle {
      position: absolute;
      cursor: pointer;
      color: #fff !important;
      margin-left: 0 !important
    }

    .mb15 {
      margin-bottom: 15px !important
    }

    .rentalfont {
      font-size: 11px !important
    }

    .checkout-calendar p {
      line-height: 1.2em;
      margin-bottom: 10px;
      margin-top: 20px
    }

    .cookiemar {
      margin-top: 0 !important
    }

    .conmar {
      margin-bottom: -35px
    }

    .place0rder {
      font-size: 50px;
      padding-bottom: 15px;
      padding-top: 15px;
      margin-left: 0 !important
    }
  }

  @media(max-width:414px) {
    .dropdown {
      top: -60px !important;
      color: #fff !important
    }

    .callfont {
      color: #fff;
      font-size: 25px !important
    }

    .col-xs-6.col-sm-6.col-md-1.pt25 {
      width: 50% !important;
      float: left !important;
      margin-top: -10px;
      padding-left: 0
    }

      .col-xs-6.col-sm-6.col-md-1.pt25 .col-xs-3 {
        width: 50%;
        float: left;
        margin-top: 0
      }

        .col-xs-6.col-sm-6.col-md-1.pt25 .col-xs-3 a {
          margin-left: 0 !important
        }

    .col-xs-12.col-sm-6.col-md-1.pt25 ul {
      display: inline-block
    }

      .col-xs-12.col-sm-6.col-md-1.pt25 ul a {
        margin-left: 0 !important
      }

    .fa.fa-twitter {
      margin-left: 50px !important
    }

    .fa.fa-linkedin {
      margin-left: -75px !important
    }

    .fa.fa-instagram {
      margin-left: -170px !important
    }

    .fa.fa-facebook-square {
      margin-left: 100px !important
    }

    .contentMain {
      background: #fff url(/images/contentLogo.png) no-repeat center 98.4% !important;
      padding: 36px 10px 0;
      text-align: center;
      background-size: 100% !important;
      margin-top: 215px !important
    }

    .login a i.fa.fa-user {
      margin: 0 !important;
      font-size: 20px
    }

    .toggle {
      left: 0;
      color: #fff;
      margin-top: -4px !important;
      font-size: 20px !important
    }

    .checkout-step1 .checkout-left, .checkout-right {
      width: 100% !important;
      float: none;
      margin: 0 !important
    }

    #VpProductLabel {
      font-size: 13px !important
    }

    .scroll-to-top {
      display: none
    }

    .contentMain {
      background: #fff url(/images/contentLogo.png) no-repeat center 22% !important;
      padding: 36px 10px 0;
      text-align: center;
      background-size: 100% !important
    }

    .js #Nav_menu_Left .toggle {
      top: -10px;
      z-index: 9999
    }

    #Nav_menu_Left label {
      padding: 0 .25em;
      font-size: 50px
    }

    .mobilecontact {
      display: block !important
    }

    .mobilecontact1 {
      display: none !important
    }

    .login-page1 {
      background:  #8BC400;
      color: #fff;
      width: 410px !important;
      margin-left: 2px !important;
      padding: 20px 30px;
      position: relative;
      overflow: hidden;
      margin-top: 20px;
      margin-bottom: 20px
    }

    .login1 {
      margin-top: -23px !important
    }

    .navbar-nav > li > .dropdown-menu {
      margin-top: -11px !important;
      margin-right: -10px !important
    }

    input[type=checkbox] {
      margin: 9px 0 0;
      margin-left: 20px;
      margin-top: 1px \9;
      line-height: normal
    }

    #searchHolder .formGroup .choose-equip {
      height: 0;
      margin-top: -4px;
      color: #0090DA !important;
      font-size: 1.1em !important;
      border: none !important
    }

    #logomobile {
      display: block !important
    }

    #logodesktop {
      display: none !important
    }

    .owl-carousel .item .item-caption {
      height: 100%;
      width: 72%
    }

    #searchHolder .navbar-form {
      background: #fdfdfd url(/img/searchBg.jpg) repeat-x;
      border: none !important;
      width: 100%;
      padding: 0;
      margin: 0;
      padding-top: 8px;
      border-radius: 6px;
      -moz-border-radius: 6px;
      -webkit-border-radius: 6px
    }

    #searchHolder input[type="text"], #searchHolder select {
      font: .875em LatoRegular;
      text-transform: uppercase;
      padding: 4px 18px 7px 27px;
      background: none;
      outline: none;
      color: #333;
      width: 100%;
      float: left
    }

    .productboxpaddinng {
      position: relative;
      min-height: 1px;
      padding-right: 0 !important;
      padding-left: 0 !important;
      float: left !important;
      width: 50% !important
    }

    #tit {
      font-family: 'ralewayextrabold';
      color: #0090DA !important;
      font-size: 2em;
      padding-bottom: 20px;
      margin: -3px 0 0 !important
    }

    .arrow {
      margin-top: 0 !important;
      width: 25% !important
    }

    .responsive-menu {
      float: none !important;
      height: 40px !important
    }

    #Nav_menu_Left, #Nav_menu_Left .sub-nav {
      position: fixed;
      top: 40px;
      bottom: 0;
      width: 17.75em;
      background: #222;
      z-index: 9999;
      left: -17.75em
    }

    .js #Nav_menu_Left .toggle {
      top: 20px;
      z-index: 9999
    }
  }

  @media (max-width: 375px) {
    .prod-area .rate {
      font: bold 1.5em/1.1em LatoWebBold;
      top: 18%;
      height: 85px;
      padding-top: 21px;
      width: 50% !important
    }

    .banner_headBox p {
      font-size: 20px !important
    }

    .terms .checkbox {
      font-size: 13px !important;
      position: relative !important;
      display: inline-block !important;
      min-height: 20px !important;
      margin: 40px 0 10px 10px !important
    }

    .product-thumb-info-content {
      background: #8CC641;
      padding: 8px 0;
      text-align: center;
      width: 100%;
      text-transform: uppercase;
      position: relative;
      z-index: 9;
      margin: 5px;
      height: 65px;
      font-size: 11px !important;
    }

    .js #Nav_menu_Left .toggle {
      top: 20px;
      z-index: 9999
    }

    .arrow {
      margin-top: 0 !important;
      width: 25% !important
    }

    #searchHolder .formGroup .choose-equip {
      color: #0090DA !important;
      font-size: .99em !important
    }

    #searchHolder h2 {
      font: 3.6em TrashHand;
      margin-bottom: 10px;
      margin-top: 20px
    }

    input[type=checkbox] {
      margin: 20px 0 0;
      margin-left: 20px;
      margin-top: 1px \9;
      line-height: normal
    }

    .navbar-nav > li > .dropdown-menu {
      margin-top: -11px !important;
      margin-right: -10px !important
    }

    .login-page1 {
      background:  #8BC400;
      color: #fff;
      width: 370px !important;
      margin-left: 2px !important;
      padding: 20px 30px;
      position: relative;
      overflow: hidden;
      margin-top: 20px;
      margin-bottom: 20px
    }

    .js #Nav_menu_Left .toggle {
      top: 20px;
      z-index: 9999
    }

    #Nav_menu_Left label {
      padding: 0 .25em;
      font-size: 50px
    }

    #searchHolder {
      clear: both;
      margin-top: -35px !important
    }

    .about, .collections {
      margin-top: 50px
    }

    .login-wrapper .list-inline {
      display: block
    }

      .login-wrapper .list-inline li + li {
        margin-left: 0
      }

    .menu-shop .dropdown-menu {
      min-width: 300px
    }

    .btn {
      min-width: 0
    }

    .pro-tabs > li {
      display: block;
      margin: 0
    }

      .pro-tabs > li a {
        width: 100%
      }

    .toolbar p.pull-left {
      float: none !important
    }

    .toolbar p.pull-left, .toolbar .list-sort.pull-right {
      float: none !important
    }

    .featured-box .formDropdown {
      width: 111%
    }
  }

  .list-select .color {
    margin-left: 2px;
    margin-right: 2px;
    padding: 9px 9px 10px
  }

  .fa.fa-facebook-square {
    margin-left: 85px !important
  }

  .fa.fa-linkedin {
    margin-left: -85px !important
  }

  .fa.fa-twitter {
    margin-left: 50px !important
  }

  .checkout-step1 .checkout-left, .checkout-right {
    width: 100% !important;
    float: none;
    margin: 0 !important
  }

  .toggle {
    position: absolute;
    cursor: pointer;
    color: #fff !important;
    margin-left: 0 !important
  }

  .mb15 {
    margin-bottom: 15px !important
  }

  #VpProductLabel {
    font-size: 12px !important
  }

  .rentalfont {
    font-size: 10px !important
  }

  .login {
    margin: -21px 0 0
  }

  .scroll-to-top.visible {
    filter: alpha(opacity=10);
    opacity: 1
  }

  .scroll-to-top {
    display: none
  }

  #logomobile {
    display: none !important
  }

  #logodesktop {
    display: block !important
  }

  .contentMain {
    background: #fff url(../images/contentLogo.png) no-repeat center 25.2% !important;
    padding: 36px 10px 0;
    text-align: center;
    background-size: 100% !important;
    margin-top: 200px !important
  }

  #searchHolder input[type="text"], #searchHolder select {
    font: .875em LatoRegular;
    text-transform: uppercase;
    padding: 1px 18px 7px 27px;
    background: none;
    outline: none;
    color: #333;
    width: 100%;
    float: left
  }

  @media(max-width:330px) {
    #searchHolder .formGroup .choose-equip {
      color: #0090DA !important;
      font-size: 1em !important
    }

    .callfont {
      color: #fff;
      font-size: 27px !important
    }

    .list-select .color {
      margin-left: 1px;
      margin-right: 0;
      padding: 9px 5px 10px
    }

    .fa.fa-facebook-square {
      margin-left: 60px !important
    }

    .fa.fa-linkedin {
      margin-left: -120px !important
    }

    .fa.fa-twitter {
      margin-left: 25px !important
    }

    .fa.fa-instagram {
      margin-left: -185px !important
    }

    .title span {
      padding: 0 40px;
      position: relative;
      z-index: 2;
      font-family: 'ralewayextralight';
      font-size: 1.4em;
      font-weight: lighter
    }

    .footer img {
      margin-left: -10px !important;
      margin-top: 10px
    }

    .checkout-step1 .checkout-left, .checkout-right {
      width: 80% !important;
      float: none;
      margin: 0 !important
    }

    .ui-widget.ui-widget-content {
      border: none;
      width: 100%;
      overflow-x: scroll !important
    }

    #VpProductLabel {
      font-size: 10px !important;
      font: bold
    }

    .footer address {
      font: .9em LatoWeb;
      line-height: 2;
      margin-left: 15px
    }

    .toggle {
      position: absolute;
      cursor: pointer;
      color: #fff;
      margin-left: -5px
    }

    .scroll-to-top {
      display: none
    }

    .contentMain {
      background: #fff url(../images/contentLogo.png) no-repeat center 102.4% !important;
      padding: 36px 10px 0;
      text-align: center;
      background-size: 100% !important
    }

    #logomobile {
      display: block !important
    }

    #logodesktop {
      display: none !important
    }

    #tit {
      font-family: 'ralewayextrabold';
      color: #0090DA !important;
      font-size: 1.5em;
      padding-bottom: 20px;
      margin: 10px 0 0 !important
    }

    .product-title {
      margin-top: 25px;
      font-size: 25px !important
    }

    .seotagsfont {
      font-size: 8px !important
    }

    .prod-area .rate {
      font: bold 1.7em/1.1em LatoWebBold;
      top: 18%;
      height: 85px;
      padding-top: 18px;
      width: 56% !important
    }

    .prod-area .info {
      text-transform: uppercase;
      font-size: 11px !important;
      text-align: center;
      padding: 12px 13%;
      min-height: 70px;
      margin-bottom: 0;
      color: #06c
    }
  }
