/*@import url('assets/css/adwords.css');*/
/*@import url('assets/css/latofonts.css');*/
@import url('assets/bootstrap/css/bootstrap.min.css');
/*@import url('assets/css/fonts/font-awesome/css/font-awesome.css');*/
@import url('https://cdnjs.cloudflare.com/ajax/libs/font-awesome/6.0.0-beta3/css/all.min.css');

/*@import url('assets/vendor/owl-carousel/owl.carousel.css');
@import url('assets/vendor/owl-carousel/owl.theme.css');
@import url('assets/vendor/owl-carousel/owl.transitions.css');*/

@import url('assets/css/theme.css');
/*@import url('assets/css/theme-animate.css');*/ 
@import url('assets/css/theme-elements.css');

@import url('assets/css/theme-responsive.css');
/*@import url('assets/css/custom.css');*/
@import url('assets/css/Blog.css');
/*@import url('assets/css/loader.css');*/

/*@import url('assets/ccard/card.css');*/

@import '~@angular/material/prebuilt-themes/deeppurple-amber.css';




.cdk-overlay-dark-backdrop {
  background: rgba(0,0,0,.6) !important;
}





.mat-radio-button.mat-accent.mat-radio-checked .mat-radio-outer-circle {
  border-color: white !important;
  background-color: white !important;
}

.mat-radio-inner-circle {
  background-color: black !important;
}

.mat-radio-outer-circle {
  border-color: black;
  background-color: white !important;
}








.mat-drawer-content {
  position: relative;
  z-index: 1;
  display: block;
  height: 100%;
  overflow: unset !important;
}

.mat-drawer-container {
margin-top:0px !important;
}

@font-face {
  font-family: 'Material Icons';
  font-style: normal;
  font-weight: 400;
  src: url(https://fonts.gstatic.com/s/materialicons/v50/flUhRq6tzZclQEJ-Vdg-IuiaDsNc.woff2) format('woff2');
}

.mat-drawer-container {
  background-color: white !important;
}

.mat-checkbox-inner-container {
  display: inline-block;
  height: 30px !important;
  line-height: 0;
  margin: auto;
  margin-right: auto;
  margin-left: auto;
  margin-right: 8px;
  order: 0;
  position: relative;
  vertical-align: middle;
  white-space: nowrap;
  width: 30px !important;
  flex-shrink: 0;
}

.mat-checkbox-checked.mat-accent .mat-checkbox-background, .mat-checkbox-indeterminate.mat-accent .mat-checkbox-background {
  background-color: #8CC641 !important;
}



button:focus {
  outline: none !important;
}

button:active {
  outline: none !important;
}


.btn:focus {
  outline: 0px solid transparent;
border-radius:none !important;
}

.btn{
  border-radius: 0px !important;
}



.material-icons {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 24px;
  line-height: 1;
  letter-spacing: normal;
  text-transform: none;
  display: inline-block;
  white-space: nowrap;
  word-wrap: normal;
  direction: ltr;
  -moz-font-feature-settings: 'liga';
  -moz-osx-font-smoothing: grayscale;
}


.mat-tab-body-content {
overflow:hidden !important;
}


.mat-tab-label .mat-tab-label-content {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  white-space: nowrap;
  font-size: 30px;
  color: #0090DA !important;
}

.mat-tab-label {
  font-size: 20px !important;
  font-family: inherit;
  border-left: 3px solid  #8BC400
}



.mat-ink-bar {
  background-color:  #8BC400;
  height: 3px !important;
}

.mat-tab-label {
  width: 33% !important;
}


.mat-tab-group.mat-primary .mat-ink-bar, .mat-tab-nav-bar.mat-primary .mat-ink-bar {
  background-color: #673ab7;
  background-color: #0090DA !important;
  height:3px;
}

/*@font-face {
  font-family: scriptin;
  src: url(fonts/scriptin.ttf) format('truetype');
}*/
/*@font-face {
  font-family: 'SCRIPTIN';
  src: url(/fonts/SCRIPTIN.ttf) format("truetype");
}*/

/*.cdk-overlay-pane {
  height: 82% !important;
}


#cdk-overlay-0 {
  height: 82% !important;
}*/


.mat-drawer-container {
  background-color: white !important;
}

/*@media only screen and (max-width: 1500px) and (min-width: 1150px) {
  .cdk-overlay-pane {
    height: 95% !important;
  }


  #cdk-overlay-0 {
    height: 95% !important;
  }
}*/









  @media(max-width: 375px) {
    .mat-tab-label .mat-tab-label-content {
    display: -webkit-inline-box;
    display: inline-flex;
    -webkit-box-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    align-items: center;
    white-space: nowrap;
    font-size: 20px;
    color: #0090DA !important;
  }
  

  .mat-tab-label {
    height: 48px;
    padding: 0 24px;
    cursor: pointer;
    box-sizing: border-box;
    opacity: .6;
    min-width: 160px;
    text-align: center;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    white-space: nowrap;
    position: relative;
  }


  .mat-tab-label {
    min-width: 72px;
  }


}

.btn{
  border-radius: 10px !important;
}

.mat-dialog-container {
  border-radius: 10px !important;
}

